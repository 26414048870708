import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const HeaderTitle = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const title = useMemo(() => {
    if (location.pathname === '/home') {
      return t('dashboard.dashboard_title');
    }
    if (location.pathname.includes('campaigns/')) {
      return t('campaign_page.title');
    }
    if (location.pathname.includes('campaigns')) {
      return t('campaigns_page.title');
    }
    if (location.pathname.includes('transactions')) {
      return t('transactions_page.title');
    }
  }, [i18n.language, location.pathname]);

  return (
    <Typography variant="h4" fontWeight={600}>
      {title}
    </Typography>
  );
};
