import { Select, SelectProps } from '@mui/material';
import { Typography } from '@mui/material/styles/createTypography';
import { styled } from '@mui/system';
import React from 'react';

import { UiScrollArea } from './UiScrollArea';

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  '& .MuiSelect-select': {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),

    '& .MuiTypography-root': {
      fontSize: `${(theme.typography as Typography).pxToRem(14)} !important`,
    },

    '&[role="combobox"]': {
      paddingRight: `${theme.spacing(6)} !important`,
    },
  },
  '& svg': {
    right: theme.spacing(2.7),
  },
})) as unknown as typeof Select;

export const UISelect = ({
  variant = 'outlined',
  ...props
}: Omit<SelectProps, 'variant'> & { variant?: SelectProps['variant'] }) => {
  return (
    <StyledSelect
      {...props}
      variant={variant}
      MenuProps={{
        MenuListProps: {
          style: { padding: 0, maxHeight: 268 },
          component: UiScrollArea,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          elevation: 0,
          sx: ({ shadows, palette }) => ({
            boxShadow: shadows[16],
            display: 'flex',
            maxHeight: 300,
            overflow: 'hidden',
            borderRadius: '20px',
            border: `1px solid ${palette.divider}`,
            padding: '16px 0 16px 16px',
          }),
        },
      }}
    />
  );
};
