import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const DoubleArrowSvgIcon = (props?: SvgIconProps & { pathFillColor?: string }) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M7.64168 11.0831C7.52631 11.0827 7.41368 11.048 7.31802 10.9835C7.22236 10.919 7.14798 10.8276 7.10427 10.7208C7.06057 10.614 7.0495 10.4967 7.07248 10.3837C7.09545 10.2706 7.15143 10.1669 7.23335 10.0856L9.91668 7.41398C9.97136 7.35975 10.0148 7.29523 10.0444 7.22415C10.074 7.15306 10.0892 7.07682 10.0892 6.99981C10.0892 6.9228 10.074 6.84656 10.0444 6.77548C10.0148 6.70439 9.97136 6.63987 9.91668 6.58565L7.23335 3.91398C7.17867 3.85975 7.13527 3.79523 7.10566 3.72415C7.07604 3.65306 7.0608 3.57682 7.0608 3.49981C7.0608 3.4228 7.07604 3.34656 7.10566 3.27547C7.13527 3.20439 7.17867 3.13987 7.23335 3.08564C7.34264 2.977 7.49049 2.91602 7.6446 2.91602C7.79871 2.91602 7.94655 2.977 8.05585 3.08564L10.7334 5.76315C11.0611 6.09127 11.2451 6.53606 11.2451 6.99981C11.2451 7.46356 11.0611 7.90835 10.7334 8.23648L8.05585 10.914C8.00134 10.968 7.9367 11.0108 7.86563 11.0398C7.79455 11.0689 7.71845 11.0836 7.64168 11.0831Z"
        fill={props?.pathFillColor}
      />
      <path
        d="M3.5583 11.0831C3.44294 11.0827 3.3303 11.048 3.23465 10.9835C3.13899 10.919 3.0646 10.8276 3.0209 10.7208C2.97719 10.614 2.96613 10.4967 2.9891 10.3837C3.01208 10.2706 3.06806 10.1669 3.14997 10.0856L6.2358 6.99981L3.14997 3.91398C3.0953 3.85975 3.0519 3.79523 3.02228 3.72415C2.99267 3.65306 2.97742 3.57682 2.97742 3.49981C2.97742 3.4228 2.99267 3.34656 3.02228 3.27547C3.0519 3.20439 3.0953 3.13987 3.14997 3.08564C3.25927 2.977 3.40711 2.91602 3.56122 2.91602C3.71533 2.91602 3.86318 2.977 3.97247 3.08564L7.47247 6.58565C7.52715 6.63987 7.57054 6.70439 7.60016 6.77548C7.62977 6.84656 7.64502 6.9228 7.64502 6.99981C7.64502 7.07682 7.62977 7.15306 7.60016 7.22415C7.57054 7.29523 7.52715 7.35975 7.47247 7.41398L3.97247 10.914C3.91796 10.968 3.85332 11.0108 3.78225 11.0398C3.71118 11.0689 3.63508 11.0836 3.5583 11.0831Z"
        fill={props?.pathFillColor}
      />
    </SvgIcon>
  );
};
