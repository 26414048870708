import { LanguageTag } from '../../../@types/i18next';

export interface ISystemState {
  themeMode: Nullable<'dark' | 'light'>;
  language: Nullable<LanguageTag>;
}

export const systemInitialState: ISystemState = {
  themeMode: null,
  language: null,
};
