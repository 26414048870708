import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { DataGridPro, DataGridProProps, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';

import { autosizeOptions } from '../constants';

export const UiDataGrid = ({ apiRef: propsApiRef, ...props }: DataGridProProps) => {
  const internalApiRef = useGridApiRef();

  const apiRef = propsApiRef ?? internalApiRef;

  useEffect(() => {
    setTimeout(() => apiRef?.current?.autosizeColumns(autosizeOptions), 300);
  }, []);

  return (
    <DataGridPro
      apiRef={apiRef}
      autoHeight={true}
      autosizeOptions={autosizeOptions}
      autosizeOnMount
      sx={(theme) => {
        const propsSx = (typeof props.sx === 'function' ? props.sx(theme) : (props.sx ?? {})) as SystemStyleObject;
        return {
          color: theme.palette.text.secondary,
          '--DataGrid-containerBackground': 'transparent',
          '--DataGrid-rowBorderColor': theme.palette.divider,
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.cell}`]: {
            border: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.row}:last-child`]: {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          [`& .${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 400,
            fontSize: '14px',
          },
          [`& .${gridClasses.row}`]: {
            fontWeight: 500,
            fontSize: '16px',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          border: 'none',
          borderRadius: 0,
          ...propsSx,
        };
      }}
      {...props}
    />
  );
};
