import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../../shared';

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const isAuth = useAppSelector((state) => state.authorizedUser.isAuth);

  return isAuth ? <>{children}</> : <Navigate to="auth/login" replace />;
};
