import { Stack } from '@mui/material';
import React from 'react';

import { UiDateRangePicker, UiSearchInput } from '../../../../shared';
import { useCampaignsFilter } from '../model/useCampaignsFilter';

export const CampaignsFilter = () => {
  const { filters, setCampaignsDataFilter, setSearchText } = useCampaignsFilter();

  const onChangeDate = (value: [Nullable<Date>, Nullable<Date>]) => {
    const [startDate, endDate] = value;
    if (!startDate || !endDate) return;
    setCampaignsDataFilter(new Date(startDate), new Date(endDate));
  };

  return (
    <Stack direction="row" spacing={2} flexGrow={1}>
      <UiDateRangePicker value={[new Date(filters.startDate), new Date(filters.endDate)]} onChange={onChangeDate} />
      <UiSearchInput sx={{ flexGrow: 1 }} onChange={(event) => setSearchText(event.target.value)} />
    </Stack>
  );
};
