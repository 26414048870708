import { format as formatFns } from 'date-fns';
import { enGB, ru } from 'date-fns/locale';

import { useAppSelector } from '../store';

export type FormatFn = typeof formatFns;

const locales = {
  en: enGB,
  ru,
};

export const useDate = () => {
  const locale = useAppSelector((state) => state.system.language);

  const format: typeof formatFns = (date, formatStr, options) =>
    formatFns(date, formatStr, { ...options, locale: locales[locale || 'en'] });

  return {
    format,
    locale: locales[locale || 'en'],
  };
};
