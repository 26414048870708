import { createSlice } from '@reduxjs/toolkit';

type TransactionSliceState = {
  range: { year: number; month: number } | undefined;
};

const initialState = { range: undefined } satisfies TransactionSliceState as TransactionSliceState;

export const transactionsSlice = createSlice({
  name: 'transactions',
  reducerPath: 'transactions',
  initialState,
  reducers: {
    setRange: (state, action) => {
      state.range = action.payload;
    },
  },
});

export const { setRange: setRangeAction } = transactionsSlice.actions;
