import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { alpha, useTheme } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React, { forwardRef } from 'react';

export const UiCheckboxCircle = forwardRef((props: CheckboxProps, ref) => {
  const { palette } = useTheme();

  return (
    <Checkbox
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-expect-error
      inputRef={ref}
      color={'primary'}
      icon={<RadioButtonUncheckedIcon htmlColor={alpha(palette.primary.main, 0.2)} />}
      checkedIcon={<CheckCircleIcon />}
      {...props}
    />
  );
});

UiCheckboxCircle.displayName = 'UiCheckboxCircle';
