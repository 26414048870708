import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LogoutSvgIcon } from '../../../shared/assets/images/LogoutSvgIcon';
import { UIDialog } from '../../../shared/ui/dialog/UIDialog';
import { useLogout } from '../model/useLogout';

interface ILogoutMainMenuButtonProps {
  isMiny?: boolean;
}

export const LogoutMainMenuButton: FC<ILogoutMainMenuButtonProps> = ({ isMiny }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { logout } = useLogout();
  const [open, setOpen] = useState(false);

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onDisagreeHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={({ palette }) => ({
          padding: '15px 20px',
          borderRadius: '16px',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: palette.action.hover,
          },
        })}
        onClick={() => setOpen(true)}
      >
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LogoutSvgIcon pathfill={theme.palette.text.secondary} />
          </Box>
          {!isMiny && (
            <Typography variant="body2" fontSize={14} fontWeight={500} color={theme.palette.text.secondary}>
              {t('logout')}
            </Typography>
          )}
        </Stack>
      </Box>

      <UIDialog titleKey={'logout'} subTitleKey={'logoutQuestion'} open={open} onClose={onCloseHandler}>
        <Stack direction={'row'} spacing={2} justifyContent={'center'}>
          <Button variant={'contained-secondary'} fullWidth onClick={onDisagreeHandler}>
            {t('buttons.cancel')}
          </Button>
          <Button variant={'contained'} fullWidth onClick={logout} color="error">
            {t('buttons.exit')}
          </Button>
        </Stack>
      </UIDialog>
    </>
  );
};
