import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LanguageTag } from '../../../@types/i18next';
import { setLanguage } from '../../i18n';
import { ISystemState, systemInitialState } from './systemState';

const setTheme = (state: ISystemState, action: PayloadAction<ISystemState['themeMode']>) => {
  return { ...state, themeMode: action.payload };
};

export const changeLanguageAsyncThunk = createAsyncThunk<LanguageTag, LanguageTag>(
  'system/changeLanguage',
  async (langTag) => {
    return await setLanguage(langTag);
  },
);

export const systemSlice = createSlice({
  name: 'system',
  reducerPath: 'system',
  initialState: systemInitialState,
  reducers: { setTheme },
  extraReducers: (builder) => {
    builder.addCase(changeLanguageAsyncThunk.fulfilled, (state, action) => {
      state.language = action.payload;
    });
  },
});

export const { setTheme: setThemeAction } = systemSlice.actions;
