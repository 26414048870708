import React from 'react';

import { CampaignDto } from '../../../../shared';
import { UIDialog } from '../../../../shared/ui/dialog/UIDialog';
import { UpdateCampaignForm } from './UpdateCampaignForm';

interface IUpdateCampaignModalProps extends CampaignDto {
  open: boolean;
  onClose: () => void;
}

export const UpdateCampaignModal = ({ open, onClose, ...campaign }: IUpdateCampaignModalProps) => {
  return (
    <UIDialog open={open} titleKey={'campaigns_page.edit_campaign'} onClose={onClose}>
      <UpdateCampaignForm {...campaign} onSuccess={onClose} onCancel={onClose} />
    </UIDialog>
  );
};
