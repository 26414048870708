import { styled } from '@mui/system';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import React, { ComponentProps, MutableRefObject, PropsWithChildren, forwardRef } from 'react';

const ScrollAreaRoot = styled(ScrollArea.Root, {
  shouldForwardProp: (prop) => prop !== 'barColor' && prop !== 'thumbColor',
})<{ barColor?: string; thumbColor?: string }>`
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;

  .ScrollAreaViewport {
    width: 100%;
    height: 100%;
    max-height: inherit;
    border-radius: inherit;
    padding-right: 16px;
  }

  .ScrollAreaScrollbar {
    display: flex;
    user-select: none;
    touch-action: none;
    background: ${({ barColor }) => barColor ?? 'transparent'};
    transition: background 160ms ease-out;
    border-radius: 4px;
  }

  .ScrollAreaViewport:has(+ .ScrollAreaScrollbar) {
    padding-right: 30px;
  }

  .ScrollAreaScrollbar[data-orientation='vertical'] {
    width: 4px;
    margin-right: 10px;
  }

  .ScrollAreaThumb {
    flex: 1;
    background: ${({ theme, thumbColor }) => thumbColor ?? theme.palette.scrollThumb};
    position: relative;
    border-radius: 4px;
    width: 4px;
  }

  .ScrollAreaThumb::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }
`;

// убрана поддержка горизонтального скролла, если понадобится можно сделать по аналогии с докой https://www.radix-ui.com/primitives/docs/components/scroll-area
export const UiScrollArea = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ComponentProps<typeof ScrollAreaRoot> & { viewportRef?: MutableRefObject<HTMLDivElement | null> }>
>(function UiScrollArea({ children, viewportRef, ...props }, ref) {
  return (
    <ScrollAreaRoot className="ScrollAreaRoot" {...props} ref={ref} type={'auto'}>
      <ScrollArea.Viewport className="ScrollAreaViewport" ref={viewportRef}>
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
    </ScrollAreaRoot>
  );
});
