import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const BurgerMenuSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M22.5 10.5H1.5C0.671578 10.5 0 11.1716 0 12C0 12.8284 0.671578 13.5 1.5 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5Z"
        fill="#AAAFB3"
      />
      <path
        d="M1.5 6.50004H22.5C23.3284 6.50004 24 5.82846 24 5.00003C24 4.17161 23.3284 3.50003 22.5 3.50003H1.5C0.671578 3.50003 0 4.17161 0 5.00003C0 5.82846 0.671578 6.50004 1.5 6.50004Z"
        fill="#AAAFB3"
      />
      <path
        d="M11.25 17.5H0.75C0.335789 17.5 0 18.1716 0 19C0 19.8284 0.335789 20.5 0.75 20.5H11.25C11.6642 20.5 12 19.8284 12 19C12 18.1716 11.6642 17.5 11.25 17.5Z"
        fill="#AAAFB3"
      />
    </SvgIcon>
  );
};
