import { resetAuthUserAction } from '../../../entities';
import { authApi, useAppDispatch } from '../../../shared';

export const useLogout = () => {
  const dispatch = useAppDispatch();

  const logout = () => {
    authApi.util.resetApiState();
    dispatch(resetAuthUserAction());
  };

  return {
    logout,
  };
};
