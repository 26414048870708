import { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { removeActiveChannelAction, selectActiveChanelAction, useChannels } from '../../../../entities';
import { useAppDispatch, useAppSelector } from '../../../../shared';

export const useSelectActiveChannel = () => {
  const location = useLocation();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const activeChannelId = useAppSelector((state) => state.activeChannel.id);
  const { selectedChannels, isUninitializedChannels, isFetchingChannels } = useChannels();
  const dispatch = useAppDispatch();

  const replacePath = useCallback(() => {
    if (location.pathname.includes('campaigns') && activeChannelId) {
      selectActiveChannel(activeChannelId);
      setUrlSearchParams((prev) => ({
        ...prev,
        channelId: activeChannelId,
      }));
    }
  }, [activeChannelId]);

  const selectActiveChannel = (id: number) => {
    dispatch(selectActiveChanelAction(id));
  };

  const removeActiveChannel = () => {
    dispatch(removeActiveChannelAction());
  };

  useEffect(() => {
    if (isUninitializedChannels || isFetchingChannels) return;
    const ids = selectedChannels?.ids;
    const id = Number(urlSearchParams.get('channelId')) || activeChannelId;
    if (!ids?.length) {
      removeActiveChannel();
    }
    if (id && !selectedChannels?.entities[id]) {
      if (ids?.length) {
        selectActiveChannel(ids[0]);
      } else {
        removeActiveChannel();
      }
    }
    if (ids?.length && !id) {
      selectActiveChannel(ids[0]);
    }
  }, [selectedChannels, isUninitializedChannels]);

  useEffect(() => {
    replacePath();
  }, [replacePath, activeChannelId]);

  return {
    activeChannelId,
    selectedChannels,
    isUninitializedChannels,
    isFetchingChannels,
    selectActiveChannel,
    removeActiveChannel,
  };
};
