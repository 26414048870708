import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { FormHelperText } from '@mui/material';
import React, { CSSProperties, PropsWithChildren } from 'react';

export const UIErrorFormHelperText = ({ children, style }: PropsWithChildren<{ style?: CSSProperties }>) => {
  return (
    <FormHelperText error sx={{ display: 'flex', alignItems: 'center' }} style={style}>
      <GppMaybeOutlinedIcon sx={{ mr: 1 }} />
      {children}
    </FormHelperText>
  );
};
