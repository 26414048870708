import { GridAutosizeOptions, GridColTypeDef } from '@mui/x-data-grid';

export const baseColSettings: Omit<GridColTypeDef, 'field'> = {
  editable: false,
  sortable: true,
  resizable: true,
  filterable: false,
  disableColumnMenu: true,
  minWidth: 100,
  align: 'left',
  headerAlign: 'left',
};

export const autosizeOptions: GridAutosizeOptions = {
  includeOutliers: true,
  includeHeaders: true,
};
