import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField } from '@mui/material';
import React, { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CampaignDto, UIErrorFormHelperText } from '../../../../shared';
import { UpdateCampaignRequest } from '../../../../shared/api/services/campaign/dto/UpdateCampaignRequest';
import { required } from '../../../../shared/helpers/formValidationRules';
import { useUpdateCampaign } from '../model/useUpdateCampaign';

interface FormValues extends Omit<UpdateCampaignRequest, 'channelId' | 'id'> {}

interface ICreateCampaignFormProps extends CampaignDto {
  onCancel: () => void;
  onSuccess: () => void;
}

export const UpdateCampaignForm: FC<ICreateCampaignFormProps> = ({ onCancel, onSuccess, ...campaign }) => {
  const { isLoadingUpdateCampaign, updateCampaign } = useUpdateCampaign();
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: campaign.name,
      description: campaign.description,
    },
  });

  const onSave: SubmitHandler<FormValues> = (values) => {
    updateCampaign({ ...values, id: campaign.id }).then(onSuccess);
  };

  return (
    <>
      <Stack spacing={2}>
        <Controller
          control={control}
          rules={{
            required: required('name'),
          }}
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
            <TextField
              value={value}
              autoFocus
              placeholder={t('name')}
              error={invalid}
              helperText={invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
              onChange={(event) => onChange(event.target.value)}
            />
          )}
          name={'name'}
        />

        <Controller
          control={control}
          rules={{
            required: required('description'),
          }}
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
            <TextField
              value={value}
              variant={'outlined'}
              placeholder={t('description')}
              multiline
              rows={10}
              error={invalid}
              helperText={invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
              onChange={(event) => onChange(event.target.value)}
            />
          )}
          name={'description'}
        />
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <Button color={'error'} variant={'contained-secondary'} sx={{ flexGrow: 1 }} onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
        <LoadingButton
          variant={'contained'}
          type={'submit'}
          loading={isLoadingUpdateCampaign}
          sx={{ flexGrow: 1 }}
          onClick={handleSubmit(onSave)}
        >
          {t('buttons.save')}
        </LoadingButton>
      </Stack>
    </>
  );
};
