import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Stack, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { removeMetricIdAction, selectMetricIdAction } from '../../../entities';
import { DashboardMetricID, DashboardMetricList } from '../../../entities/dashboard-data/model/dashboardState';
import { useAppDispatch, useAppSelector } from '../../../shared';
import { darkTheme } from '../../../shared/themes/theme';

export const DashboardChartStatSelect = () => {
  const { t } = useTranslation();
  const selectedMetricIds = useAppSelector((state) => state.dashboard.selectedMetricIds);
  const dispatch = useAppDispatch();

  const toggleSelect = (id: DashboardMetricID) => {
    selectedMetricIds.includes(id) ? dispatch(removeMetricIdAction(id)) : dispatch(selectMetricIdAction(id));
  };

  return (
    <Stack spacing={1} direction={'row'} alignItems={'center'}>
      {DashboardMetricList.map(({ id, color }) => (
        <FormControlLabel
          key={id}
          control={
            <Checkbox
              sx={{
                color,
                '&.Mui-checked': {
                  color,
                },
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={selectedMetricIds.includes(id)}
              onChange={() => toggleSelect(id)}
            />
          }
          label={
            <Typography variant={'body2'} color={darkTheme.palette.text.tertiary}>
              {t(id)}
            </Typography>
          }
        />
      ))}
    </Stack>
  );
};
