import { useAppSelector, useCreateCampaignMutation } from '../../../../shared';
import { CreateCampaignRequest } from '../../../../shared/api/services/campaign/dto/CreateCampaignRequest';

export const useCreateCampaign = () => {
  const [triggerCreateCampaign, { isLoading: isLoadingCreateCampaign }] = useCreateCampaignMutation();
  const activeChannelId = useAppSelector((state) => state.activeChannel.id);

  const createCampaign = async (request: Omit<CreateCampaignRequest, 'channelId'>) => {
    if (!activeChannelId) return;
    triggerCreateCampaign({ ...request, channelId: activeChannelId.toString() }).unwrap();
  };

  return {
    createCampaign,
    isLoadingCreateCampaign,
  };
};
