import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, { useEffect, useState } from 'react';

export const useDeferredComponent = (
  isLoading: boolean | undefined,
  component:
    | React.ReactElement
    | ReactJSXElement
    | ReactJSXElement[]
    | (() => React.ReactElement | ReactJSXElement | ReactJSXElement[]),
) => {
  const [showComponent, setShowComponent] = useState(isLoading);

  useEffect(() => {
    if (isLoading) {
      setShowComponent(true);
    } else {
      const timeout = setTimeout(() => setShowComponent(false), 500); // 500ms minimum display time
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  if (showComponent) {
    if (Array.isArray(component)) {
      return React.createElement('div', undefined, component);
    }
    if (typeof component === 'function') {
      const result = component();
      if (Array.isArray(result)) {
        return React.createElement('div', undefined, result);
      }
      return result;
    }
    return component;
  }
};
