import { useMemo } from 'react';

import { useAppSelector } from '../../../shared';
import { DashboardMetricList } from './dashboardState';
import { useDashboard } from './useDashboard';

export const useDashboardChart = () => {
  const { dataDashboard } = useDashboard();
  const selectedMetricIds = useAppSelector((state) => state.dashboard.selectedMetricIds);
  const selectedChanelDataItems = useMemo(
    () => DashboardMetricList.filter(({ id }) => selectedMetricIds.includes(id)),
    [selectedMetricIds],
  );
  const dates = dataDashboard?.ids;

  return {
    dataDashboard,
    dates,
    selectedChanelDataItems,
    selectedMetricIds,
  };
};
