import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { chanelApi } from '../../../shared';
import { IChannelsState, channelsInitialState } from './channelsState';

const selectChanel = (state: IChannelsState, action: PayloadAction<number>) => {
  state.selectedChannelIds.push(action.payload);
};

const unselectChannel = (state: IChannelsState, action: PayloadAction<number>) => {
  state.selectedChannelIds = state.selectedChannelIds.filter((channelId) => channelId !== action.payload);
};

const unselectAllChannels = (state: IChannelsState) => {
  state.selectedChannelIds.splice(0);
};

const selectAllChannels = (state: IChannelsState, action: PayloadAction<number[]>) => {
  state.selectedChannelIds = action.payload;
};

export const channelsSlice = createSlice({
  name: 'channels',
  reducerPath: 'channels',
  initialState: channelsInitialState,
  reducers: { selectChanel, unselectChannel, unselectAllChannels, selectAllChannels },
  extraReducers: (builder) => {
    builder.addMatcher(chanelApi.endpoints.getChanelList.matchFulfilled, (state, action) => {
      state.selectedChannelIds = [...action.payload.ids];
    });
  },
});

export const {
  selectChanel: selectChanelAction,
  unselectChannel: unselectChanelAction,
  unselectAllChannels: unselectAllChannelsAction,
  selectAllChannels: selectAllChannelsAction,
} = channelsSlice.actions;
