import React, { Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { PublicRoute } from '../../app/router/PublicRoute';
import { AuthPagesLayout } from './AuthPagesLayout';

const LoginPage = React.lazy(() => import('../../pages/auth/LoginPage'));
const RegisterPage = React.lazy(() => import('../../pages/auth/RegisterPage'));
const TermsAndConditions = React.lazy(() => import('../../pages/auth/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('../../pages/auth/PrivacyPolicy'));

export const authRoutes: RouteObject = {
  path: '/auth',
  element: <AuthPagesLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/auth/login" replace />,
    },
    {
      path: '/auth/login',
      element: (
        <Suspense>
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        </Suspense>
      ),
    },
    {
      path: '/auth/register',
      element: (
        <Suspense>
          <PublicRoute>
            <RegisterPage />
          </PublicRoute>
        </Suspense>
      ),
    },
    {
      path: '/auth/terms-and-conditions',
      element: (
        <Suspense>
          <TermsAndConditions />
        </Suspense>
      ),
    },
    {
      path: '/auth/privacy-policy',
      element: (
        <Suspense>
          <PrivacyPolicy />
        </Suspense>
      ),
    },
  ],
};
