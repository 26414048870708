import { LoginRequestDto, useLoginExternalMutation, useLoginMutation } from '../../../shared';

export const useLogin = () => {
  const [triggerLogin, { isLoading: isLoadingLogin, error: loginError }] = useLoginMutation({
    fixedCacheKey: 'login',
  });

  const [triggerLoginExternal, { isLoading: isLoadingLoginExternal, error: loginExternalError }] =
    useLoginExternalMutation({
      fixedCacheKey: 'login-external',
    });

  const login = (request: LoginRequestDto) => {
    triggerLogin(request);
  };

  return {
    login,
    isLoadingLogin,
    loginError,
    triggerLoginExternal,
    isLoadingLoginExternal,
    loginExternalError,
  };
};
