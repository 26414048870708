import { ButtonBase, Stack, Typography, alpha } from '@mui/material';
import { styled } from '@mui/system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../shared';
import { SparksSvgIcon } from '../../../shared/assets/images/SparksSvgIcon';
import SunSvgIcon from '../../../shared/assets/images/SunSvgIcon';
import { useChangeTheme } from '../model/useChangeTheme';

interface IChangeThemeButtonsGroupProps {
  isMiny?: boolean;
}

export const ChangeThemeButtonsGroup: FC<IChangeThemeButtonsGroupProps> = ({ isMiny }) => {
  const { t } = useTranslation();
  const { changeThemeMode } = useChangeTheme();
  const { isDark, theme } = useTheme();

  return (
    <Stack direction={isMiny ? 'column' : 'row'} spacing={2} style={{ marginLeft: -6 }}>
      <ToggleButton selected={!isDark} onClick={() => changeThemeMode('light')}>
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
          <SunSvgIcon fill={!isDark ? theme.palette.common.white : theme.palette.primary.main} />
          {!isMiny && <Typography variant={'body2'}>{t('theme.light')}</Typography>}
        </Stack>
      </ToggleButton>
      <ToggleButton selected={isDark} onClick={() => changeThemeMode('dark')}>
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
          <SparksSvgIcon fill={isDark ? theme.palette.common.white : theme.palette.primary.main} />
          {!isMiny && <Typography variant={'body2'}>{t('theme.dark')}</Typography>}
        </Stack>
      </ToggleButton>
    </Stack>
  );
};

const ToggleButton = styled(ButtonBase)<{ selected?: boolean }>(({ theme, selected }) => ({
  flexGrow: 1,
  minHeight: '48px',
  padding: '0 28px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: selected ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.08),
  color: selected ? theme.palette.common.white : theme.palette.primary.main,
}));
