import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const CrossSvgButton = (props?: SvgIconProps & { pathFill?: string }) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M17.7805 0.220078C17.6398 0.0794748 17.4491 0.000488281 17.2502 0.000488281C17.0513 0.000488281 16.8606 0.0794748 16.72 0.220078L9.00021 7.93983L1.28046 0.220078C1.13981 0.0794748 0.949079 0.000488281 0.750206 0.000488281C0.551333 0.000488281 0.360602 0.0794748 0.219956 0.220078C0.0793528 0.360724 0.000366211 0.551455 0.000366211 0.750328C0.000366211 0.949201 0.0793528 1.13993 0.219956 1.28058L7.93971 9.00033L0.219956 16.7201C0.0793528 16.8607 0.000366211 17.0515 0.000366211 17.2503C0.000366211 17.4492 0.0793528 17.6399 0.219956 17.7806C0.360602 17.9212 0.551333 18.0002 0.750206 18.0002C0.949079 18.0002 1.13981 17.9212 1.28046 17.7806L9.00021 10.0608L16.72 17.7806C16.8606 17.9212 17.0513 18.0002 17.2502 18.0002C17.4491 18.0002 17.6398 17.9212 17.7805 17.7806C17.9211 17.6399 18 17.4492 18 17.2503C18 17.0515 17.9211 16.8607 17.7805 16.7201L10.0607 9.00033L17.7805 1.28058C17.9211 1.13993 18 0.949201 18 0.750328C18 0.551455 17.9211 0.360724 17.7805 0.220078Z"
        fill={props?.pathFill}
      />
    </SvgIcon>
  );
};
