import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import { UpdateCampaignIconButton } from '../../../features/campaign/update-campaign';
import { CampaignDto } from '../../../shared';
import { baseColSettings } from '../../../shared/constants';
import { numberWithSpaces } from '../../../shared/helpers/numberWithSpaces';
import { numberWithThreeFixedDecimals } from '../../../shared/helpers/numberWithThreeFixedDecimals';

export const getCampaignsTableColumns = (t: TFunction, theme: Theme, searchParams: URLSearchParams): GridColDef[] => [
  {
    field: 'id',
    headerName: 'id',
    type: 'string',
    ...baseColSettings,
  },
  {
    field: 'name',
    headerName: t('name'),
    type: 'string',
    renderCell: (params) => (
      <Typography height={'100%'} display={'flex'} alignItems={'center'}>
        <Link
          to={{ pathname: `/campaigns/${params.row.id}`, search: searchParams.toString() }}
          style={{ color: theme.palette.text.secondary }}
        >
          {params.row.name}
        </Link>
      </Typography>
    ),
    ...baseColSettings,
  },
  {
    field: 'ads',
    headerName: t('ads'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'activeAds',
    headerName: t('active_ads'),
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'impressions',
    headerName: t('impressions'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'clicks',
    headerName: t('clicks'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'subscriptions',
    headerName: t('subscriptions'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'ctr',
    headerName: t('ctr'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'cpc',
    headerName: t('cpc'),
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'cr',
    headerName: t('cr'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'expenses',
    headerName: t('expenses'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'cps',
    headerName: t('cps'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'budget',
    headerName: t('budget') as string,
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'actions',
    headerName: t('actions'),
    ...baseColSettings,
    renderCell: (params) => <UpdateCampaignIconButton {...(params.row as unknown as CampaignDto)} />,
    renderHeader: () => t('actions'),
  },
];
