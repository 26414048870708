import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { useCallback } from 'react';

import { UIDateRangeFilter } from '../../../shared/ui/UIDateRangeFilter';
import { useDashboardFilter } from '../model/useDashboardFilter';

interface IDashboardFilterProps {
  RightSlot?: JSX.Element;
}

export const DashboardFilter = ({ RightSlot }: IDashboardFilterProps) => {
  const { filtersStartDate, filtersEndDate, setDates } = useDashboardFilter();

  const onChangeData = useCallback(
    (value: [Nullable<Date>, Nullable<Date>]) => {
      const [startDate, endDate] = value;
      if (!startDate || !endDate) return;
      setDates(new Date(startDate), new Date(endDate));
    },
    [setDates, filtersStartDate, filtersEndDate],
  );

  return (
    <Paper elevation={0}>
      <Stack direction="row" spacing={2} paddingX={1} paddingY={1}>
        <UIDateRangeFilter startDate={filtersStartDate} endDate={filtersEndDate} onChangeDate={onChangeData} />
        {RightSlot}
      </Stack>
    </Paper>
  );
};
