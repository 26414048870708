import { useCallback } from 'react';

import { removeChannelFromFiltersAction, useChannels } from '../../../../entities';
import { unselectAllChannelsAction, unselectChanelAction } from '../../../../entities/channel/model/channelsSlice';
import { useAppDispatch } from '../../../../shared';

export const useUnselectChannel = () => {
  const dispatch = useAppDispatch();
  const { selectedChannelsIds } = useChannels();

  const unselectChannelById = useCallback(
    (id: number) => {
      dispatch(unselectChanelAction(id));
      dispatch(removeChannelFromFiltersAction(id));
    },
    [dispatch],
  );

  const unselectAllChannels = useCallback(() => {
    if (!selectedChannelsIds.length) return;
    dispatch(unselectAllChannelsAction());
  }, [dispatch, selectedChannelsIds]);

  return {
    unselectChannelById,
    unselectAllChannels,
    disabled: !selectedChannelsIds?.length,
  };
};
