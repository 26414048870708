import { IconButton } from '@mui/material';
import React from 'react';

import { ClearSvgIcon } from '../../../../shared/assets/images/ClearSvgIcon';
import { useUnselectChannel } from '../model/useUnselectChannel';

export const ClearAllSelectedChannelsIconButton = () => {
  const { disabled, unselectAllChannels } = useUnselectChannel();

  return (
    <IconButton size={'small'} disabled={disabled} onClick={unselectAllChannels}>
      <ClearSvgIcon
        sx={{
          width: '16px',
          height: '16px',
        }}
        viewBox="0 0 16 16"
      />
    </IconButton>
  );
};
