import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, alpha } from '@mui/material';
import React from 'react';

import { CampaignDto, useTheme } from '../../../../shared';
import { UpdateCampaignModal } from './UpdateCampaignModal';

export const UpdateCampaignIconButton = (props: CampaignDto) => {
  const [open, setOpen] = React.useState(false);
  const { isDark } = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleClickOpen();
        }}
        sx={({ palette }) => ({
          backgroundColor: isDark ? alpha(palette.primary.dark, 0.2) : palette.grey.A200,
          color: isDark ? palette.primary.main : '#374957',
        })}
      >
        <EditOutlinedIcon />
      </IconButton>
      <UpdateCampaignModal open={open} onClose={handleClose} {...props} />
    </>
  );
};
