import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import { BACKEND_API_URL } from '../../constants';
import { RootState } from '../store';

export const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: `${BACKEND_API_URL}/api`,
  prepareHeaders: (headers, api) => {
    const state = api.getState() as RootState;
    const token = state.authorizedUser.token;
    if (token) {
      headers.set('Authorization', 'Bearer ' + token);
    }
    return headers;
  },
});
