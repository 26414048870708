import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UIDialog } from '../../../../shared/ui/dialog/UIDialog';
import { CreateCampaignForm } from './CreateCampaignForm';

export const CreateCampaignButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant={'contained'} startIcon={<AddIcon />} onClick={handleClickOpen}>
        {t('campaigns_page.create_campaign')}
      </Button>

      <UIDialog titleKey={'campaigns_page.create_campaign'} open={open} onClose={handleCancel}>
        <CreateCampaignForm onSuccess={handleCancel} onCancel={handleCancel} />
      </UIDialog>
    </>
  );
};
