import i18n, { ParseKeys } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Language, LanguageTag, SupportedLanguageTags } from '../../@types/i18next';
import Ru from '../assets/images/russia.png';
import UK from '../assets/images/united-kingdom.png';
import en from './en.json';
import ru from './ru.json';

export const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
} as const;
export const defaultNS = 'translation';
export const SUPPORTED_LANGUAGES: SupportedLanguageTags = ['en', 'ru'];
export type TFuncKeyApp = ParseKeys;

export const languages: Language[] = [
  {
    tag: 'en',
    fallbackName: 'English',
    flagImageLink: UK,
  },
  {
    tag: 'ru',
    fallbackName: 'Russian',
    flagImageLink: Ru,
  },
];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: SUPPORTED_LANGUAGES,
    resources,
    ns: ['translation'],
    defaultNS,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export const setLanguage = async (langTag: LanguageTag): Promise<LanguageTag> => {
  await i18n.changeLanguage(langTag);
  return i18n.language as LanguageTag;
};

export default i18n;
