import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PasswordEyeSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#clip0_43_177)">
        <path
          d="M17.4532 7.06409C16.2899 5.16959 13.6439 1.99109 8.99992 1.99109C4.35592 1.99109 1.70992 5.16959 0.546666 7.06409C0.187062 7.64572 -0.00341797 8.31602 -0.00341797 8.99984C-0.00341797 9.68366 0.187062 10.354 0.546666 10.9356C1.70992 12.8301 4.35592 16.0086 8.99992 16.0086C13.6439 16.0086 16.2899 12.8301 17.4532 10.9356C17.8128 10.354 18.0033 9.68366 18.0033 8.99984C18.0033 8.31602 17.8128 7.64572 17.4532 7.06409ZM16.1744 10.1503C15.1754 11.7748 12.9142 14.5086 8.99992 14.5086C5.08567 14.5086 2.82442 11.7748 1.82542 10.1503C1.61177 9.80462 1.4986 9.40624 1.4986 8.99984C1.4986 8.59343 1.61177 8.19505 1.82542 7.84934C2.82442 6.22484 5.08567 3.49109 8.99992 3.49109C12.9142 3.49109 15.1754 6.22184 16.1744 7.84934C16.3881 8.19505 16.5012 8.59343 16.5012 8.99984C16.5012 9.40624 16.3881 9.80462 16.1744 10.1503Z"
          fill="#8498B5"
        />
        <path
          d="M8.99982 5.24988C8.25814 5.24988 7.53311 5.46981 6.91643 5.88187C6.29975 6.29392 5.8191 6.87959 5.53527 7.56482C5.25144 8.25004 5.17718 9.00404 5.32187 9.73147C5.46657 10.4589 5.82372 11.1271 6.34817 11.6515C6.87262 12.176 7.5408 12.5331 8.26823 12.6778C8.99566 12.8225 9.74966 12.7483 10.4349 12.4644C11.1201 12.1806 11.7058 11.7 12.1178 11.0833C12.5299 10.4666 12.7498 9.74156 12.7498 8.99988C12.7486 8.00568 12.3532 7.05255 11.6502 6.34954C10.9472 5.64654 9.99402 5.25107 8.99982 5.24988ZM8.99982 11.2499C8.55481 11.2499 8.1198 11.1179 7.74979 10.8707C7.37978 10.6235 7.09139 10.272 6.92109 9.86092C6.75079 9.44978 6.70623 8.99738 6.79305 8.56093C6.87987 8.12447 7.09416 7.72356 7.40883 7.40889C7.7235 7.09422 8.12441 6.87993 8.56087 6.79311C8.99732 6.70629 9.44972 6.75085 9.86086 6.92115C10.272 7.09145 10.6234 7.37983 10.8706 7.74985C11.1179 8.11986 11.2498 8.55487 11.2498 8.99988C11.2498 9.59662 11.0128 10.1689 10.5908 10.5909C10.1689 11.0128 9.59656 11.2499 8.99982 11.2499Z"
          fill="#8498B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_177">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
