import React, { SVGProps } from 'react';

export const SparksSvgIcon = (props: SVGProps<SVGPathElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_619)">
        <path
          d="M3.74988 10.5C4.16409 10.5 4.49988 10.1642 4.49988 9.75C4.49988 9.33579 4.16409 9 3.74988 9C3.33566 9 2.99988 9.33579 2.99988 9.75C2.99988 10.1642 3.33566 10.5 3.74988 10.5Z"
          fill="white"
          {...props}
        />
        <path
          d="M0.75 7.50012C1.16421 7.50012 1.5 7.16433 1.5 6.75012C1.5 6.33591 1.16421 6.00012 0.75 6.00012C0.335786 6.00012 0 6.33591 0 6.75012C0 7.16433 0.335786 7.50012 0.75 7.50012Z"
          fill="white"
          {...props}
        />
        <path
          d="M3.74988 4.49988C4.16409 4.49988 4.49988 4.16409 4.49988 3.74988C4.49988 3.33566 4.16409 2.99988 3.74988 2.99988C3.33566 2.99988 2.99988 3.33566 2.99988 3.74988C2.99988 4.16409 3.33566 4.49988 3.74988 4.49988Z"
          fill="white"
          {...props}
        />
        <path
          d="M0.75 1.5C1.16421 1.5 1.5 1.16421 1.5 0.75C1.5 0.335786 1.16421 0 0.75 0C0.335786 0 0 0.335786 0 0.75C0 1.16421 0.335786 1.5 0.75 1.5Z"
          fill="white"
          {...props}
        />
        <path
          d="M17.2499 10.5C17.6641 10.5 17.9999 10.1642 17.9999 9.75C17.9999 9.33579 17.6641 9 17.2499 9C16.8357 9 16.4999 9.33579 16.4999 9.75C16.4999 10.1642 16.8357 10.5 17.2499 10.5Z"
          fill="white"
          {...props}
        />
        <path
          d="M14.25 7.50012C14.6642 7.50012 15 7.16433 15 6.75012C15 6.33591 14.6642 6.00012 14.25 6.00012C13.8358 6.00012 13.5 6.33591 13.5 6.75012C13.5 7.16433 13.8358 7.50012 14.25 7.50012Z"
          fill="white"
          {...props}
        />
        <path
          d="M17.2499 4.49988C17.6641 4.49988 17.9999 4.16409 17.9999 3.74988C17.9999 3.33566 17.6641 2.99988 17.2499 2.99988C16.8357 2.99988 16.4999 3.33566 16.4999 3.74988C16.4999 4.16409 16.8357 4.49988 17.2499 4.49988Z"
          fill="white"
          {...props}
        />
        <path
          d="M14.25 1.5C14.6642 1.5 15 1.16421 15 0.75C15 0.335786 14.6642 0 14.25 0C13.8358 0 13.5 0.335786 13.5 0.75C13.5 1.16421 13.8358 1.5 14.25 1.5Z"
          fill="white"
          {...props}
        />
        <path
          d="M10.4999 17.9999C10.9141 17.9999 11.2499 17.6641 11.2499 17.2499C11.2499 16.8357 10.9141 16.4999 10.4999 16.4999C10.0857 16.4999 9.74989 16.8357 9.74989 17.2499C9.74989 17.6641 10.0857 17.9999 10.4999 17.9999Z"
          fill="white"
          {...props}
        />
        <path
          d="M7.5 15C7.91421 15 8.25 14.6642 8.25 14.25C8.25 13.8358 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.8358 6.75 14.25C6.75 14.6642 7.08579 15 7.5 15Z"
          fill="white"
          {...props}
        />
        <path
          d="M10.4999 12.0001C10.9141 12.0001 11.2499 11.6643 11.2499 11.2501C11.2499 10.8359 10.9141 10.5001 10.4999 10.5001C10.0857 10.5001 9.74989 10.8359 9.74989 11.2501C9.74989 11.6643 10.0857 12.0001 10.4999 12.0001Z"
          fill="white"
          {...props}
        />
        <path
          d="M7.5 8.99989C7.91421 8.99989 8.25 8.6641 8.25 8.24988C8.25 7.83567 7.91421 7.49988 7.5 7.49988C7.08579 7.49988 6.75 7.83567 6.75 8.24988C6.75 8.6641 7.08579 8.99989 7.5 8.99989Z"
          fill="white"
          {...props}
        />
        <path
          d="M11.2501 2.25C10.8523 2.25 10.4707 2.09196 10.1894 1.81066C9.90813 1.52936 9.7501 1.14782 9.7501 0.75C9.7501 0.551088 9.67108 0.360322 9.53043 0.21967C9.38978 0.0790176 9.19901 0 9.0001 0C8.80119 0 8.61042 0.0790176 8.46977 0.21967C8.32912 0.360322 8.2501 0.551088 8.2501 0.75C8.2501 1.14782 8.09207 1.52936 7.81076 1.81066C7.52946 2.09196 7.14793 2.25 6.75011 2.25C6.55119 2.25 6.36043 2.32902 6.21978 2.46967C6.07912 2.61032 6.00011 2.80109 6.00011 3C6.00011 3.19891 6.07912 3.38968 6.21978 3.53033C6.36043 3.67098 6.55119 3.75 6.75011 3.75C7.14793 3.75 7.52946 3.90804 7.81076 4.18934C8.09207 4.47064 8.2501 4.85218 8.2501 5.25C8.2501 5.44891 8.32912 5.63968 8.46977 5.78033C8.61042 5.92098 8.80119 6 9.0001 6C9.19901 6 9.38978 5.92098 9.53043 5.78033C9.67108 5.63968 9.7501 5.44891 9.7501 5.25C9.7501 4.85218 9.90813 4.47064 10.1894 4.18934C10.4707 3.90804 10.8523 3.75 11.2501 3.75C11.449 3.75 11.6398 3.67098 11.7804 3.53033C11.9211 3.38968 12.0001 3.19891 12.0001 3C12.0001 2.80109 11.9211 2.61032 11.7804 2.46967C11.6398 2.32902 11.449 2.25 11.2501 2.25Z"
          fill="white"
          {...props}
        />
        <path
          d="M5.25 14.2499C4.85218 14.2499 4.47064 14.0918 4.18934 13.8105C3.90804 13.5292 3.75 13.1477 3.75 12.7499C3.75 12.551 3.67098 12.3602 3.53033 12.2195C3.38968 12.0789 3.19891 11.9999 3 11.9999C2.80109 11.9999 2.61032 12.0789 2.46967 12.2195C2.32902 12.3602 2.25 12.551 2.25 12.7499C2.25 13.1477 2.09196 13.5292 1.81066 13.8105C1.52936 14.0918 1.14782 14.2499 0.75 14.2499C0.551088 14.2499 0.360322 14.3289 0.21967 14.4695C0.0790176 14.6102 0 14.801 0 14.9999C0 15.1988 0.0790176 15.3895 0.21967 15.5302C0.360322 15.6709 0.551088 15.7499 0.75 15.7499C1.14782 15.7499 1.52936 15.9079 1.81066 16.1892C2.09196 16.4705 2.25 16.852 2.25 17.2499C2.25 17.4488 2.32902 17.6395 2.46967 17.7802C2.61032 17.9208 2.80109 17.9999 3 17.9999C3.19891 17.9999 3.38968 17.9208 3.53033 17.7802C3.67098 17.6395 3.75 17.4488 3.75 17.2499C3.75 16.852 3.90804 16.4705 4.18934 16.1892C4.47064 15.9079 4.85218 15.7499 5.25 15.7499C5.44891 15.7499 5.63968 15.6709 5.78033 15.5302C5.92098 15.3895 6 15.1988 6 14.9999C6 14.801 5.92098 14.6102 5.78033 14.4695C5.63968 14.3289 5.44891 14.2499 5.25 14.2499Z"
          fill="white"
          {...props}
        />
        <path
          d="M17.2499 14.2499C16.8521 14.2499 16.4705 14.0918 16.1892 13.8105C15.9079 13.5292 15.7499 13.1477 15.7499 12.7499C15.7499 12.551 15.6709 12.3602 15.5302 12.2195C15.3896 12.0789 15.1988 11.9999 14.9999 11.9999C14.801 11.9999 14.6102 12.0789 14.4696 12.2195C14.3289 12.3602 14.2499 12.551 14.2499 12.7499C14.2499 13.1477 14.0919 13.5292 13.8105 13.8105C13.5292 14.0918 13.1477 14.2499 12.7499 14.2499C12.551 14.2499 12.3602 14.3289 12.2196 14.4695C12.0789 14.6102 11.9999 14.801 11.9999 14.9999C11.9999 15.1988 12.0789 15.3895 12.2196 15.5302C12.3602 15.6709 12.551 15.7499 12.7499 15.7499C13.1477 15.7499 13.5292 15.9079 13.8105 16.1892C14.0919 16.4705 14.2499 16.852 14.2499 17.2499C14.2499 17.4488 14.3289 17.6395 14.4696 17.7802C14.6102 17.9208 14.801 17.9999 14.9999 17.9999C15.1988 17.9999 15.3896 17.9208 15.5302 17.7802C15.6709 17.6395 15.7499 17.4488 15.7499 17.2499C15.7499 16.852 15.9079 16.4705 16.1892 16.1892C16.4705 15.9079 16.8521 15.7499 17.2499 15.7499C17.4488 15.7499 17.6396 15.6709 17.7802 15.5302C17.9209 15.3895 17.9999 15.1988 17.9999 14.9999C17.9999 14.801 17.9209 14.6102 17.7802 14.4695C17.6396 14.3289 17.4488 14.2499 17.2499 14.2499Z"
          fill="white"
          {...props}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_619">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
