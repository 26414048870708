import { createSlice } from '@reduxjs/toolkit';

import { authApi } from '../../../shared';
import { authorizedUserInitialState } from './AuthorizedUserState';

const reset = () => {
  return authorizedUserInitialState;
};

export const authorizedUserSlice = createSlice({
  name: 'authorizedUser',
  reducerPath: 'authorizedUser',
  initialState: authorizedUserInitialState,
  reducers: { reset },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      return { ...state, token: action.payload.token, isAuth: true };
    });
    builder.addMatcher(authApi.endpoints.login.matchRejected, (state) => {
      return { ...state, token: null, isAuth: false, userData: null };
    });
    builder.addMatcher(authApi.endpoints.register.matchFulfilled, (state, action) => {
      return { ...state, token: action.payload.token, isAuth: true };
    });
    builder.addMatcher(authApi.endpoints.register.matchRejected, (state) => {
      return { ...state, token: null, isAuth: false };
    });
    builder.addMatcher(authApi.endpoints.loginExternal.matchFulfilled, (state, action) => {
      return { ...state, token: action.payload.token, isAuth: true };
    });
    builder.addMatcher(authApi.endpoints.loginExternal.matchRejected, (state) => {
      return { ...state, token: null, isAuth: false, userData: null };
    });
    builder.addMatcher(authApi.endpoints.getUserData.matchFulfilled, (state, action) => {
      return { ...state, userData: action.payload, isAuth: true };
    });
    builder.addMatcher(authApi.endpoints.getUserData.matchRejected, (state) => {
      return { ...state, userData: null, isAuth: false };
    });
  },
});

export const { reset: resetAuthUserAction } = authorizedUserSlice.actions;
