import { GetAdsRequest } from '../../../shared/api/services/campaign/dto/GetAdsRequest';

export interface IAdState {
  filters: Omit<GetAdsRequest, 'campaignId' | 'channelId'>;
  searchText: string;
  selectedAds: number[];
}

const now = new Date();
const threeMonthAgo = new Date();
threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);

export const adInitialState: IAdState = {
  filters: {
    status: null,
    startDate: threeMonthAgo.toISOString(),
    endDate: now.toISOString(),
  },
  searchText: '',
  selectedAds: [],
};
