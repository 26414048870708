import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetCampaignsListRequest } from '../../../shared/api/services/campaign/dto/GetCampaignsListRequest';
import { ICampaignSate, campaignInitialState } from './campaignSate';

const setCampaignListFilters = (state: ICampaignSate, action: PayloadAction<Partial<GetCampaignsListRequest>>) => {
  state.campaignsListFilter = { ...state.campaignsListFilter, ...action.payload };
};

const setCampaignsSearchText = (state: ICampaignSate, action: PayloadAction<string>) => {
  state.searchText = action.payload;
};

export const campaignSlice = createSlice({
  name: 'campaign',
  reducerPath: 'campaign',
  initialState: campaignInitialState,
  reducers: { setCampaignListFilters, setCampaignsSearchText },
});

export const {
  setCampaignListFilters: setCampaignListFiltersAction,
  setCampaignsSearchText: setCampaignsSearchTextAction,
} = campaignSlice.actions;
