import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { DateRangePicker, DateRangePickerProps, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { PickerValidDate } from '@mui/x-date-pickers/models';
import React from 'react';

import { CalendarSvgIcon } from '../assets/images/CalendarSvgIcon';
import { useDate } from '../hooks/useDate';

export const UiDateRangePicker = <
  TDate extends PickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false,
>(
  props: DateRangePickerProps<TDate, TEnableAccessibleFieldDOMStructure>,
) => {
  const { locale } = useDate();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DateRangePicker
        slots={{
          textField: (props: TextFieldProps) => (
            <TextField
              {...props}
              InputProps={{
                ...props.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarSvgIcon
                      sx={{
                        '&.MuiSvgIcon-root': {
                          width: '18px !important',
                          height: '18px',
                          marginLeft: '16px',
                        },
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              variant={'outlined'}
              sx={(theme) => ({
                '.MuiInputBase-root': {
                  color: `${theme.palette.text.secondary} !important`,

                  input: {
                    paddingLeft: '6px',
                    fontSize: 14,
                  },
                },
              })}
              label={''}
            />
          ),
          // desktopPaper: Paper,
        }}
        calendars={1}
        {...props}
      />
    </LocalizationProvider>
  );
};
