import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const LogoSvgImage = ({
  textColor,
  isMiny,
  ...props
}: SvgIconProps & { textColor?: string; isMiny?: boolean }) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="181" height="32" viewBox="0 0 181 32" fill="none" {...props}>
      {!isMiny && (
        <>
          <path
            d="M59.0332 23.6396C59.0332 24.0882 58.8687 24.4694 58.5398 24.7834C58.2258 25.0974 57.8445 25.2544 57.396 25.2544C57.0521 25.2544 56.7381 25.1572 56.454 24.9628C56.1849 24.7535 55.998 24.4918 55.8933 24.1779L55.2878 22.4285H48.5596L47.954 24.1779C47.8494 24.4918 47.655 24.7535 47.3709 24.9628C47.0869 25.1572 46.7729 25.2544 46.429 25.2544C45.9804 25.2544 45.5992 25.0974 45.2852 24.7834C44.9712 24.4694 44.8142 24.0882 44.8142 23.6396C44.8142 23.5349 44.8516 23.3481 44.9263 23.0789L49.2997 11.7755C49.509 11.2373 49.8529 10.8037 50.3313 10.4747C50.8098 10.1458 51.3406 9.98132 51.9237 9.98132C52.5068 9.98132 53.0376 10.1458 53.516 10.4747C53.9945 10.8037 54.3384 11.2373 54.5477 11.7755L58.8986 23.0789C58.9883 23.2882 59.0332 23.4751 59.0332 23.6396ZM49.5464 19.67H54.301L51.9237 12.8969L49.5464 19.67Z"
            fill={textColor}
          />
          <path
            d="M71.2501 11.4167V23.819C71.2501 24.2227 71.1081 24.5741 70.824 24.8731C70.5549 25.1572 70.2185 25.2992 69.8148 25.2992C69.4261 25.2992 69.0896 25.1572 68.8056 24.8731C68.5215 24.589 68.372 24.2451 68.357 23.8415V23.7517C67.4599 24.8283 66.3535 25.3665 65.0378 25.3665C63.6323 25.3665 62.481 24.8582 61.5839 23.8415C60.6868 22.8247 60.2383 21.4417 60.2383 19.6924C60.2383 17.973 60.6868 16.5974 61.5839 15.5657C62.481 14.5191 63.6323 13.9958 65.0378 13.9958C66.3834 13.9958 67.4973 14.549 68.3794 15.6555V11.4167C68.3794 11.0279 68.5215 10.6915 68.8056 10.4074C69.0896 10.1234 69.4261 9.98132 69.8148 9.98132C70.2035 9.98132 70.5399 10.1234 70.824 10.4074C71.1081 10.6915 71.2501 11.0279 71.2501 11.4167ZM68.3794 21.5987V17.7636C68.1402 17.4048 67.7889 17.1132 67.3254 16.889C66.8619 16.6497 66.3909 16.5301 65.9124 16.5301C65.105 16.5301 64.4472 16.8217 63.9388 17.4048C63.4305 17.9879 63.1763 18.7504 63.1763 19.6924C63.1763 20.6194 63.4305 21.3744 63.9388 21.9576C64.4472 22.5407 65.105 22.8322 65.9124 22.8322C66.3909 22.8322 66.8619 22.7201 67.3254 22.4958C67.7889 22.2566 68.1402 21.9576 68.3794 21.5987Z"
            fill={textColor}
          />
          <path
            d="M73.3793 23.2808C73.3793 22.9518 73.4915 22.6752 73.7158 22.451C73.955 22.2117 74.2391 22.0921 74.568 22.0921C74.7773 22.0921 74.9792 22.1444 75.1735 22.2491C76.1603 22.8621 77.1397 23.1686 78.1115 23.1686C79.3226 23.1686 79.9281 22.8023 79.9281 22.0697C79.9281 21.7408 79.7562 21.4866 79.4123 21.3072C79.0834 21.1128 78.5302 20.9408 77.7527 20.7913C76.8705 20.6418 76.1529 20.4624 75.5997 20.2531C74.1942 19.7896 73.4915 18.8401 73.4915 17.4048C73.4915 16.4479 73.8952 15.6405 74.7026 14.9826C75.51 14.3248 76.5865 13.9958 77.9321 13.9958C79.2628 13.9958 80.4515 14.3173 81.4981 14.9602C81.8719 15.1546 82.0588 15.4686 82.0588 15.9022C82.0588 16.2012 81.9466 16.4628 81.7223 16.6871C81.513 16.8964 81.2588 17.0011 80.9598 17.0011C80.7355 17.0011 80.5561 16.9562 80.4216 16.8665C79.674 16.403 78.8516 16.1713 77.9545 16.1713C77.4312 16.1713 77.0126 16.261 76.6986 16.4404C76.3996 16.6198 76.2501 16.8665 76.2501 17.1805C76.2501 17.4796 76.3996 17.7113 76.6986 17.8758C77.0126 18.0402 77.5508 18.1972 78.3134 18.3467C79.0759 18.4813 79.8011 18.6682 80.4888 18.9074C81.9391 19.4307 82.6643 20.425 82.6643 21.8903C82.6643 22.907 82.2456 23.7443 81.4084 24.4021C80.5711 25.0451 79.4273 25.3665 77.977 25.3665C76.4669 25.3665 75.1362 25.0152 73.9849 24.3124C73.5812 24.0882 73.3793 23.7443 73.3793 23.2808Z"
            fill={textColor}
          />
          <path
            d="M90.7283 24.0209C90.7283 24.589 90.4443 24.9628 89.8761 25.1422C89.4724 25.2918 88.979 25.3665 88.3959 25.3665C87.3941 25.3665 86.6316 25.1123 86.1083 24.604C85.585 24.0956 85.3233 23.363 85.3233 22.4061V16.7544H84.6281C84.2842 16.7544 83.9852 16.6348 83.731 16.3956C83.4918 16.1563 83.3722 15.8648 83.3722 15.5209C83.3722 15.177 83.4918 14.8854 83.731 14.6462C83.9852 14.392 84.2842 14.265 84.6281 14.265H85.3233V12.5829C85.3233 12.1942 85.4654 11.8577 85.7495 11.5737C86.0335 11.2896 86.3774 11.1475 86.7811 11.1475C87.1699 11.1475 87.4988 11.2896 87.7679 11.5737C88.052 11.8577 88.194 12.1942 88.194 12.5829V14.265H89.3154C89.6593 14.265 89.9509 14.392 90.1901 14.6462C90.4293 14.8854 90.5489 15.177 90.5489 15.5209C90.5489 15.8498 90.4293 16.1414 90.1901 16.3956C89.9509 16.6348 89.6593 16.7544 89.3154 16.7544H88.194V21.6436C88.194 22.0024 88.2763 22.294 88.4407 22.5182C88.6202 22.7276 88.8594 22.8322 89.1584 22.8322C89.2332 22.8322 89.3229 22.8247 89.4276 22.8098C89.5322 22.7948 89.5995 22.7874 89.6294 22.7874C89.9583 22.8023 90.22 22.9294 90.4144 23.1686C90.6237 23.3929 90.7283 23.677 90.7283 24.0209Z"
            fill={textColor}
          />
          <path
            d="M101.693 18.1V23.7742C101.693 24.1779 101.551 24.5292 101.267 24.8283C100.998 25.1123 100.661 25.2544 100.258 25.2544C99.8987 25.2544 99.5772 25.1348 99.2932 24.8955C99.024 24.6563 98.867 24.3498 98.8222 23.976C98.0596 24.903 96.9233 25.3665 95.4132 25.3665C94.4264 25.3665 93.5517 25.0451 92.7892 24.4021C92.0267 23.7592 91.6454 22.8846 91.6454 21.7781C91.6454 20.6568 92.0192 19.8045 92.7668 19.2214C93.5144 18.6233 94.3965 18.3243 95.4132 18.3243C96.9831 18.3243 98.1269 18.7654 98.8446 19.6475V18.1449C98.8446 17.5917 98.6278 17.1506 98.1942 16.8217C97.7606 16.4927 97.185 16.3283 96.4673 16.3283C95.645 16.3283 94.8451 16.5675 94.0676 17.046C93.9031 17.1506 93.7162 17.2029 93.5069 17.2029C93.2079 17.2029 92.9537 17.0983 92.7444 16.889C92.535 16.6647 92.4304 16.403 92.4304 16.104C92.4304 15.6854 92.6023 15.3714 92.9462 15.1621C94.1274 14.3846 95.4655 13.9958 96.9607 13.9958C98.3662 13.9958 99.5025 14.3173 100.37 14.9602C101.252 15.6181 101.693 16.6647 101.693 18.1ZM98.8446 22.4958V21.1502C98.3811 20.5222 97.6186 20.2082 96.557 20.2082C95.9589 20.2082 95.4655 20.3577 95.0768 20.6568C94.703 20.9558 94.5161 21.352 94.5161 21.8454C94.5161 22.3239 94.703 22.7126 95.0768 23.0116C95.4655 23.2957 95.9589 23.4378 96.557 23.4378C97.6186 23.4378 98.3811 23.1238 98.8446 22.4958Z"
            fill={textColor}
          />
          <path
            d="M110.484 24.0209C110.484 24.589 110.2 24.9628 109.631 25.1422C109.228 25.2918 108.734 25.3665 108.151 25.3665C107.15 25.3665 106.387 25.1123 105.864 24.604C105.34 24.0956 105.079 23.363 105.079 22.4061V16.7544H104.383C104.04 16.7544 103.741 16.6348 103.486 16.3956C103.247 16.1563 103.128 15.8648 103.128 15.5209C103.128 15.177 103.247 14.8854 103.486 14.6462C103.741 14.392 104.04 14.265 104.383 14.265H105.079V12.5829C105.079 12.1942 105.221 11.8577 105.505 11.5737C105.789 11.2896 106.133 11.1475 106.536 11.1475C106.925 11.1475 107.254 11.2896 107.523 11.5737C107.807 11.8577 107.949 12.1942 107.949 12.5829V14.265H109.071C109.415 14.265 109.706 14.392 109.945 14.6462C110.185 14.8854 110.304 15.177 110.304 15.5209C110.304 15.8498 110.185 16.1414 109.945 16.3956C109.706 16.6348 109.415 16.7544 109.071 16.7544H107.949V21.6436C107.949 22.0024 108.032 22.294 108.196 22.5182C108.376 22.7276 108.615 22.8322 108.914 22.8322C108.989 22.8322 109.078 22.8247 109.183 22.8098C109.288 22.7948 109.355 22.7874 109.385 22.7874C109.714 22.8023 109.975 22.9294 110.17 23.1686C110.379 23.3929 110.484 23.677 110.484 24.0209Z"
            fill={textColor}
          />
          <path
            d="M117.902 23.3929V11.8204C117.902 11.2821 118.044 10.8709 118.328 10.5869C118.627 10.2878 119.045 10.1383 119.584 10.1383H123.8C126.147 10.1383 128.054 10.8336 129.519 12.2241C130.999 13.5996 131.739 15.4013 131.739 17.6291C131.739 19.8569 130.999 21.6585 129.519 23.0341C128.054 24.4096 126.147 25.0974 123.8 25.0974H119.584C119.045 25.0974 118.627 24.9553 118.328 24.6713C118.044 24.3722 117.902 23.9461 117.902 23.3929ZM121.086 22.294H123.8C125.205 22.294 126.334 21.8454 127.187 20.9483C128.054 20.0512 128.487 18.9448 128.487 17.6291C128.487 16.2535 128.076 15.1321 127.254 14.265C126.417 13.3828 125.265 12.9417 123.8 12.9417H121.086V22.294Z"
            fill={textColor}
          />
          <path
            d="M134.134 11.9998C133.805 11.6559 133.641 11.2522 133.641 10.7887C133.641 10.3252 133.805 9.92899 134.134 9.60006C134.478 9.25617 134.882 9.08423 135.345 9.08423C135.823 9.08423 136.227 9.2487 136.556 9.57763C136.885 9.90657 137.049 10.3103 137.049 10.7887C137.049 11.2522 136.885 11.6559 136.556 11.9998C136.227 12.3287 135.823 12.4932 135.345 12.4932C134.882 12.4932 134.478 12.3287 134.134 11.9998ZM133.932 23.819V15.5433C133.932 15.1546 134.067 14.8182 134.336 14.5341C134.62 14.25 134.964 14.108 135.367 14.108C135.756 14.108 136.085 14.25 136.354 14.5341C136.638 14.8182 136.78 15.1546 136.78 15.5433V23.819C136.78 24.2078 136.638 24.5442 136.354 24.8283C136.07 25.1123 135.734 25.2544 135.345 25.2544C134.956 25.2544 134.62 25.1123 134.336 24.8283C134.067 24.5442 133.932 24.2078 133.932 23.819Z"
            fill={textColor}
          />
          <path
            d="M149.899 15.5433V24.3573C149.899 26.2113 149.323 27.5345 148.172 28.3269C147.036 29.1044 145.66 29.4932 144.045 29.4932C142.58 29.4932 141.309 29.1567 140.233 28.4839C139.844 28.2597 139.649 27.9232 139.649 27.4747C139.649 27.1458 139.762 26.8617 139.986 26.6225C140.225 26.3982 140.509 26.286 140.838 26.286C141.033 26.286 141.234 26.3458 141.444 26.4655C142.146 26.929 143.014 27.1607 144.045 27.1607C144.912 27.1607 145.623 26.9364 146.176 26.4879C146.744 26.0393 147.028 25.3516 147.028 24.4246V23.3481C146.101 24.4694 144.987 25.0301 143.686 25.0301C142.266 25.0301 141.115 24.5442 140.233 23.5723C139.35 22.5855 138.909 21.2324 138.909 19.513C138.909 17.8085 139.35 16.4628 140.233 15.476C141.115 14.4892 142.266 13.9958 143.686 13.9958C145.032 13.9958 146.146 14.549 147.028 15.6555V15.476C147.028 15.1022 147.17 14.7808 147.454 14.5117C147.753 14.2425 148.097 14.108 148.486 14.108C148.875 14.108 149.204 14.25 149.473 14.5341C149.757 14.8182 149.899 15.1546 149.899 15.5433ZM147.028 21.2623V17.7636C146.789 17.4048 146.438 17.1132 145.974 16.889C145.525 16.6497 145.062 16.5301 144.584 16.5301C143.761 16.5301 143.096 16.8067 142.587 17.3599C142.094 17.8982 141.847 18.6159 141.847 19.513C141.847 20.4101 142.094 21.1352 142.587 21.6884C143.096 22.2267 143.761 22.4958 144.584 22.4958C145.047 22.4958 145.511 22.3762 145.974 22.137C146.438 21.8977 146.789 21.6062 147.028 21.2623Z"
            fill={textColor}
          />
          <path
            d="M152.926 11.9998C152.597 11.6559 152.432 11.2522 152.432 10.7887C152.432 10.3252 152.597 9.92899 152.926 9.60006C153.27 9.25617 153.673 9.08423 154.137 9.08423C154.615 9.08423 155.019 9.2487 155.348 9.57763C155.677 9.90657 155.841 10.3103 155.841 10.7887C155.841 11.2522 155.677 11.6559 155.348 11.9998C155.019 12.3287 154.615 12.4932 154.137 12.4932C153.673 12.4932 153.27 12.3287 152.926 11.9998ZM152.724 23.819V15.5433C152.724 15.1546 152.858 14.8182 153.127 14.5341C153.412 14.25 153.755 14.108 154.159 14.108C154.548 14.108 154.877 14.25 155.146 14.5341C155.43 14.8182 155.572 15.1546 155.572 15.5433V23.819C155.572 24.2078 155.43 24.5442 155.146 24.8283C154.862 25.1123 154.525 25.2544 154.137 25.2544C153.748 25.2544 153.412 25.1123 153.127 24.8283C152.858 24.5442 152.724 24.2078 152.724 23.819Z"
            fill={textColor}
          />
          <path
            d="M164.362 24.0209C164.362 24.589 164.078 24.9628 163.51 25.1422C163.106 25.2918 162.613 25.3665 162.03 25.3665C161.028 25.3665 160.265 25.1123 159.742 24.604C159.219 24.0956 158.957 23.363 158.957 22.4061V16.7544H158.262C157.918 16.7544 157.619 16.6348 157.365 16.3956C157.125 16.1563 157.006 15.8648 157.006 15.5209C157.006 15.177 157.125 14.8854 157.365 14.6462C157.619 14.392 157.918 14.265 158.262 14.265H158.957V12.5829C158.957 12.1942 159.099 11.8577 159.383 11.5737C159.667 11.2896 160.011 11.1475 160.415 11.1475C160.804 11.1475 161.132 11.2896 161.402 11.5737C161.686 11.8577 161.828 12.1942 161.828 12.5829V14.265H162.949C163.293 14.265 163.585 14.392 163.824 14.6462C164.063 14.8854 164.183 15.177 164.183 15.5209C164.183 15.8498 164.063 16.1414 163.824 16.3956C163.585 16.6348 163.293 16.7544 162.949 16.7544H161.828V21.6436C161.828 22.0024 161.91 22.294 162.074 22.5182C162.254 22.7276 162.493 22.8322 162.792 22.8322C162.867 22.8322 162.957 22.8247 163.061 22.8098C163.166 22.7948 163.233 22.7874 163.263 22.7874C163.592 22.8023 163.854 22.9294 164.048 23.1686C164.257 23.3929 164.362 23.677 164.362 24.0209Z"
            fill={textColor}
          />
          <path
            d="M175.327 18.1V23.7742C175.327 24.1779 175.185 24.5292 174.9 24.8283C174.631 25.1123 174.295 25.2544 173.891 25.2544C173.532 25.2544 173.211 25.1348 172.927 24.8955C172.658 24.6563 172.501 24.3498 172.456 23.976C171.693 24.903 170.557 25.3665 169.047 25.3665C168.06 25.3665 167.185 25.0451 166.423 24.4021C165.66 23.7592 165.279 22.8846 165.279 21.7781C165.279 20.6568 165.653 19.8045 166.4 19.2214C167.148 18.6233 168.03 18.3243 169.047 18.3243C170.617 18.3243 171.761 18.7654 172.478 19.6475V18.1449C172.478 17.5917 172.261 17.1506 171.828 16.8217C171.394 16.4927 170.819 16.3283 170.101 16.3283C169.279 16.3283 168.479 16.5675 167.701 17.046C167.537 17.1506 167.35 17.2029 167.141 17.2029C166.842 17.2029 166.587 17.0983 166.378 16.889C166.169 16.6647 166.064 16.403 166.064 16.104C166.064 15.6854 166.236 15.3714 166.58 15.1621C167.761 14.3846 169.099 13.9958 170.594 13.9958C172 13.9958 173.136 14.3173 174.003 14.9602C174.885 15.6181 175.327 16.6647 175.327 18.1ZM172.478 22.4958V21.1502C172.015 20.5222 171.252 20.2082 170.191 20.2082C169.593 20.2082 169.099 20.3577 168.71 20.6568C168.337 20.9558 168.15 21.352 168.15 21.8454C168.15 22.3239 168.337 22.7126 168.71 23.0116C169.099 23.2957 169.593 23.4378 170.191 23.4378C171.252 23.4378 172.015 23.1238 172.478 22.4958Z"
            fill={textColor}
          />
          <path
            d="M178.152 23.819V11.4167C178.152 11.0279 178.286 10.6915 178.555 10.4074C178.839 10.1234 179.183 9.98132 179.587 9.98132C179.976 9.98132 180.305 10.1234 180.574 10.4074C180.858 10.6915 181 11.0279 181 11.4167V23.819C181 24.2078 180.858 24.5442 180.574 24.8283C180.305 25.1123 179.976 25.2544 179.587 25.2544C179.183 25.2544 178.839 25.1123 178.555 24.8283C178.286 24.5442 178.152 24.2078 178.152 23.819Z"
            fill={textColor}
          />
        </>
      )}
      <path
        d="M22.1998 2.81296C20.0343 -0.937651 14.6208 -0.937655 12.4554 2.81296L0.762124 23.0663C-0.960596 26.0501 0.400369 29.6274 3.20952 30.9654C3.38836 30.5043 3.64941 30.0661 3.99412 29.6725L11.9615 20.5754L9.81056 18.7476C8.45525 17.5959 8.9789 15.393 10.7075 14.9743L22.4302 12.1353C23.8661 11.7876 25.2238 12.9413 25.1123 14.4146L24.2021 26.4417C24.0679 28.2152 21.9783 29.0875 20.623 27.9358L18.5953 26.2127L13.9601 31.5051H29.0208C33.3517 31.5051 36.0584 26.8169 33.893 23.0663L22.1998 2.81296Z"
        fill="url(#paint0_linear_43_258)"
      />
      <path
        d="M14.7021 31.5053H15.4523L19.7564 27.6212L21.5657 29.5724C22.775 30.8766 24.9532 30.258 25.2966 28.5129L27.6254 16.6783C27.9106 15.2286 26.6992 13.9222 25.2321 14.0974L25.1147 14.1114C25.1207 14.2107 25.1201 14.3119 25.1123 14.4147L24.2021 26.4418C24.0679 28.2153 21.9784 29.0876 20.623 27.9359L18.5953 26.2128L13.952 31.5053H14.7021Z"
        fill="#0B6EAF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_43_258"
          x1="2.32511"
          y1="28.8797"
          x2="30.0796"
          y2="15.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12F2DA" />
          <stop offset="1" stopColor="#0693F6" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
