import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClearSvgIcon } from '../../../../shared/assets/images/ClearSvgIcon';
import { useUnselectChannel } from '../model/useUnselectChannel';

export const ClearAllSelectedChannelsContainedButton = () => {
  const { t } = useTranslation();
  const { disabled, unselectAllChannels } = useUnselectChannel();

  return (
    <Button
      variant={'contained'}
      size={'large'}
      disabled={disabled}
      sx={{ textTransform: 'none' }}
      startIcon={
        <ClearSvgIcon
          sx={{
            width: '16px',
            height: '16px',
          }}
          viewBox="0 0 16 16"
        />
      }
      onClick={unselectAllChannels}
    >
      {t('buttons.clear')}
    </Button>
  );
};
