import { useCallback, useEffect } from 'react';

import { setDashboardFiltersAction, useChannels } from '../../../entities';
import { useAppDispatch, useAppSelector } from '../../../shared';

export const useDashboardFilter = () => {
  const dispatch = useAppDispatch();
  const filtersStartDate = useAppSelector((state) => state.dashboard.filters.startDate);
  const filtersEndDate = useAppSelector((state) => state.dashboard.filters.endDate);
  const { selectedChannelsIds } = useChannels();

  useEffect(() => {
    dispatch(setDashboardFiltersAction({ groupIds: selectedChannelsIds.map((item) => item.toString()) }));
  }, [selectedChannelsIds]);

  const setDates = useCallback(
    (startDate: Date, endDate: Date) => {
      dispatch(setDashboardFiltersAction({ startDate: startDate.toISOString(), endDate: endDate.toISOString() }));
    },
    [setDashboardFiltersAction],
  );

  return {
    filtersStartDate,
    filtersEndDate,
    setDates,
  };
};
