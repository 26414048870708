import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';

import { activeChannelSlice, authorizedUserSlice, dashboardSlice } from '../../entities';
import { adSlice } from '../../entities/ad';
import { campaignSlice } from '../../entities/campaign/model/campaignSlice';
import { channelsSlice } from '../../entities/channel/model/channelsSlice';
import { transactionsSlice } from '../../entities/transactions/model/transactionSlice';
import { mainMenuSlice } from '../../features/main-menu/model/mainMenuSlice';
import { RootState, authApi, campaignApi, chanelApi, clientApi } from '../../shared';
import { systemSlice } from '../../shared';
import { adsApi } from '../../shared/api/services/ads/ads';
import { setLanguage } from '../../shared/i18n';

export const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [authorizedUserSlice.reducerPath]: authorizedUserSlice.reducer,
  [campaignSlice.reducerPath]: campaignSlice.reducer,
  [campaignApi.reducerPath]: campaignApi.reducer,
  [systemSlice.reducerPath]: systemSlice.reducer,
  [chanelApi.reducerPath]: chanelApi.reducer,
  [channelsSlice.reducerPath]: channelsSlice.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [dashboardSlice.reducerPath]: dashboardSlice.reducer,
  [transactionsSlice.reducerPath]: transactionsSlice.reducer,
  [activeChannelSlice.reducerPath]: activeChannelSlice.reducer,
  [adSlice.reducerPath]: adSlice.reducer,
  [adsApi.reducerPath]: adsApi.reducer,
  [mainMenuSlice.reducerPath]: mainMenuSlice.reducer,
});

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: 'root',
  storage,
  debug: process.env.NODE_ENV !== 'production',
  whitelist: [
    authorizedUserSlice.reducerPath,
    systemSlice.reducerPath,
    activeChannelSlice.reducerPath,
    mainMenuSlice.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    })
      .concat(authApi.middleware)
      .concat(chanelApi.middleware)
      .concat(clientApi.middleware)
      .concat(campaignApi.middleware)
      .concat(adsApi.middleware),
});

export const persistor = persistStore(store, undefined, async () => {
  const state = store.getState() as RootState;
  const lang = state.system.language;
  if (lang) await setLanguage(lang);
});
