import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ChevronSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none" {...props}>
      <path
        d="M11.0324 1.15752C10.9627 1.08722 10.8797 1.03143 10.7883 0.993349C10.6969 0.955272 10.5989 0.935669 10.4999 0.935669C10.4009 0.935669 10.3029 0.955272 10.2115 0.993349C10.1201 1.03143 10.0371 1.08722 9.96739 1.15752L6.53239 4.59251C6.46267 4.66281 6.37972 4.7186 6.28833 4.75668C6.19693 4.79475 6.0989 4.81436 5.99989 4.81436C5.90088 4.81436 5.80285 4.79475 5.71146 4.75668C5.62007 4.7186 5.53712 4.66281 5.46739 4.59251L2.03239 1.15752C1.96267 1.08722 1.87972 1.03143 1.78833 0.993349C1.69693 0.955272 1.5989 0.935669 1.49989 0.935669C1.40088 0.935669 1.30285 0.955272 1.21146 0.993349C1.12007 1.03143 1.03711 1.08722 0.967392 1.15752C0.827704 1.29804 0.749298 1.48813 0.749298 1.68627C0.749298 1.88441 0.827704 2.07449 0.967392 2.21502L4.40989 5.65751C4.83177 6.07886 5.40364 6.31553 5.99989 6.31553C6.59615 6.31553 7.16802 6.07886 7.58989 5.65751L11.0324 2.21502C11.1721 2.07449 11.2505 1.88441 11.2505 1.68627C11.2505 1.48813 11.1721 1.29804 11.0324 1.15752Z"
        fill="#8498B5"
      />
    </SvgIcon>
  );
};
