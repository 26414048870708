import { useCallback, useEffect } from 'react';

import {
  setCampaignListFiltersAction,
  setCampaignsSearchTextAction,
} from '../../../../entities/campaign/model/campaignSlice';
import { useAppDispatch, useAppSelector } from '../../../../shared';

export const useCampaignsFilter = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.campaign.campaignsListFilter);
  const activeChannelId = useAppSelector((state) => state.activeChannel.id);

  const setCampaignsDataFilter = (startDate: Date, endDate: Date) => {
    dispatch(setCampaignListFiltersAction({ startDate: startDate.toISOString(), endDate: endDate.toISOString() }));
  };

  useEffect(() => {
    if (activeChannelId) {
      dispatch(setCampaignListFiltersAction({ channelId: activeChannelId }));
    }
  }, [activeChannelId]);

  const setSearchText = useCallback(
    (text: string) => {
      dispatch(setCampaignsSearchTextAction(text));
    },
    [dispatch, setCampaignsSearchTextAction],
  );

  return {
    filters,
    setCampaignsDataFilter,
    setSearchText,
  };
};
