import { Box, Stack } from '@mui/material';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { MainMenu } from '../../features/main-menu/ui/MainMenu';
import { HeaderWidget } from '../../widgets/header/HeaderWidget';

const Layout = () => {
  return (
    <Suspense>
      <Box sx={{ height: '100vh', overflowY: 'auto', overflowX: 'hidden', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            gap: 42,
            padding: '20px 34px 20px 24px',
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 1,
              position: 'sticky',
              top: 20,
              height: 'calc(100vh - 40px)',
            }}
          >
            <MainMenu />
          </Box>

          <Stack spacing={2} flexGrow={1} flexShrink={1} overflow="auto">
            <HeaderWidget />
            <Box>
              <Outlet />
            </Box>
          </Stack>
        </div>
      </Box>
    </Suspense>
  );
};

export default Layout;
