import React, { Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ProtectedRoute } from '../../app/router/ProtectedRoute';
import Layout from './Layout';

const Dashboard = React.lazy(() => import('./dashboard/DashboardPage'));
const Campaigns = React.lazy(() => import('./campaigns/CampaignsPage'));
const Campaign = React.lazy(() => import('./campaign/CampaignPage'));
const Transactions = React.lazy(() => import('./transactions/TransactionsPage'));

const dashboardRoute = {
  path: '/home',
  element: (
    <Suspense>
      <Dashboard />
    </Suspense>
  ),
};

export const campaignsRoute = {
  path: '/campaigns',
  element: (
    <Suspense>
      <Campaigns />
    </Suspense>
  ),
};

export const campaignRoute = {
  path: '/campaigns/:campaignId',
  element: (
    <Suspense>
      <Campaign />
    </Suspense>
  ),
};

export const transactionsRoute = {
  path: '/transactions',
  element: (
    <Suspense>
      <Transactions />
    </Suspense>
  ),
};

export const mainRoutes: RouteObject = {
  path: '/',
  element: (
    <ProtectedRoute>
      <Layout />
    </ProtectedRoute>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={dashboardRoute.path} replace />,
    },
    dashboardRoute,
    campaignsRoute,
    campaignRoute,
    transactionsRoute,
  ],
};
