import { alpha } from '@mui/material';
import { Components, Palette, Theme, createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material/styles/createTypography';
import { Shape } from '@mui/system';

import { ChevronSvgIcon } from '../assets/images/ChevronSvgIcon';
import { DoubleArrowSvgIcon } from '../assets/images/DoubleArrowSvgIcon';

declare module '@mui/material/styles' {
  interface TypeText {
    tertiary: string;
    additional: string;
    placeholder: string;
    chart: string;
  }

  interface Palette {
    icon: {
      rectFill: string;
      pathFill: string;
    };
    listScroll: {
      bar: string;
      thumb: string;
    };
    scrollThumb: string;
    border: string;
    hoverBorder: string;
    statChip: {
      riseBack: string;
      riseText: string;
      fallBack: string;
      fallText: string;
    };
  }

  interface PaletteOptions {
    icon?: {
      rectFill: string;
      pathFill: string;
    };
    listScroll?: {
      bar: string;
      thumb: string;
    };
    scrollThumb?: string;
    border: string;
    hoverBorder: string;
    statChip?: {
      riseBack: string;
      riseText: string;
      fallBack: string;
      fallText: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'contained-secondary': true;
  }
}

const components: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      '*': {
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.icon.pathFill} ${theme.palette.icon.rectFill}`,
      },
      '.MuiPickersPopper-paper': {
        backgroundColor: `${theme.palette.background.paper} !important`,
        backgroundImage: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}) !important`,
        boxShadow: `${theme.shadows[16]} !important`,
      },
      '.MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.background.paper,
      },
    }),
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '30px',
        fontSize: '15px',
        fontWeight: 500,

        '.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.border} !important`,
          transition: 'all 150ms linear',
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.hoverBorder} !important`,
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${
            theme.palette.mode === 'light'
              ? alpha(theme.palette.primary.main, 0.4)
              : alpha(theme.palette.primary.main, 0.2)
          } !important`,
        },

        input: {
          paddingLeft: '28px',
        },
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '30px',
        height: '48px',
        paddingLeft: '36px',
        paddingRight: '36px',
      },
    },
    defaultProps: {
      disableElevation: true,
    },
    variants: [
      {
        props: {
          variant: 'contained-secondary',
        },
        style: ({ theme }) => ({
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          color: theme.palette.primary.main,
        }),
      },
    ],
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '25px',
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: (props) =>
        ChevronSvgIcon({ ...props, sx: { width: '0.5em', height: '0.5em', top: 'calc(50% - 0.25em) !important' } }),
    },
    styleOverrides: {
      root: () => ({
        '.MuiSelect-outlined': {
          paddingLeft: '28px',
        },
      }),
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        '.MuiSvgIcon-root': {
          width: 18,
          height: 18,
        },
        '&.MuiInputBase-sizeSmall': {
          height: '48px',
          input: {
            height: '30px',
            '&::placeholder': {
              color: palette.text.placeholder,
              opacity: 1,
            },
          },
        },
        '&.MuiInputBase-multiline': {
          height: 'auto',
        },
        textarea: {
          paddingLeft: '16px',
          '&::placeholder': {
            color: palette.text.placeholder,
            opacity: 1,
          },
        },
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
  },
  MuiSkeleton: {
    defaultProps: {
      sx: (theme) => ({
        backgroundColor: (theme.palette as Palette).background.paper,
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        input: {
          paddingLeft: '28px !important',
        },
      },
    },
  },
  MuiBreadcrumbs: {
    defaultProps: {
      separator: DoubleArrowSvgIcon({
        sx: ({ palette }) => ({ width: '14px', height: '14px', color: palette.text.additional }),
      }),
    },
    styleOverrides: {
      root: {
        fontWeight: 500,
      },
    },
  },
};

const shape: Shape = {
  borderRadius: 25,
};

const typography: Partial<Typography> = {
  fontFamily: 'Inter, Arial',
  subtitle1: {
    color: '#718EBF',
  },
};

export const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      paper: '#FFFFFF',
      default: '#ecedf8',
    },
    primary: {
      main: '#298af8',
    },
    text: {
      primary: '#050504',
      secondary: '#3D4A5C',
      tertiary: '#3D4F6E',
      additional: '#AAAFB3',
      placeholder: '#ADB4BF',
      chart: '#858397',
    },
    divider: '#E6E6E6',
    icon: {
      rectFill: '#EEEEEE',
      pathFill: '#AAB4C2',
    },
    action: {
      hover: '#ecf0f6',
    },
    error: {
      main: '#F44949',
    },
    scrollThumb: '#ABBED1',
    listScroll: {
      bar: '#EEEEEE',
      thumb: '#D0D0D0',
    },
    border: '#E4E4E4',
    hoverBorder: '#B4B4B4',
    statChip: {
      riseBack: '#e1f8ef',
      riseText: '#00da81',
      fallBack: '#ffdad9',
      fallText: '#fb4a4e',
    },
  },
  typography,
  shape,
  components,
});

export const darkTheme: Theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#1f2335',
      default: '#191d31',
    },
    primary: {
      main: '#298af8',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#8498B5',
      tertiary: '#718EBF',
      additional: '#8498B5',
      placeholder: '#49506D',
      chart: '#475879',
    },
    divider: '#25293A',
    action: {
      hover: '#181C2C',
    },
    icon: {
      rectFill: '#31364F',
      pathFill: '#8498B5',
    },
    error: {
      main: '#F44949',
    },
    listScroll: {
      bar: '#111111',
      thumb: '#2f2f2f',
    },
    scrollThumb: '#697091',
    border: '#373C54',
    hoverBorder: '#4b4b4b',
    statChip: {
      riseBack: '#264736',
      riseText: '#31d483',
      fallBack: '#2c1113',
      fallText: '#f64c54',
    },
  },
  typography,
  shape,
  components,
});
