import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { useTheme } from '../shared';
import '../shared/i18n';
import { MuiXLicense } from './MuiXLicense';
import './index.css';

const App: React.FC = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <MuiXLicense />
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
};

export default App;
