import { useMediaQuery } from '@mui/material';

import { useAppSelector } from '../store';
import { darkTheme, lightTheme } from './theme';

export const useTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const storedThemeMode = useAppSelector((state) => state.system.themeMode);

  const mode = storedThemeMode || prefersDarkMode;

  const isDark = mode === 'dark';

  return {
    theme: isDark ? darkTheme : lightTheme,
    isDark,
  };
};
