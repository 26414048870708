import { IconButton, IconButtonProps, useTheme } from '@mui/material';
import React from 'react';

import { CrossSvgButton } from '../assets/images/CrossSvgButton';

export const UICloseButton = (props: IconButtonProps) => {
  const { palette } = useTheme();

  return (
    <IconButton size={'small'} {...props}>
      <CrossSvgButton sx={{ width: '18px', height: '18px' }} pathFill={palette.text.additional} />
    </IconButton>
  );
};
