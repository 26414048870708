import TvIcon from '@mui/icons-material/Tv';
import { CSSObject, Divider, Stack } from '@mui/material';
import { Theme } from '@mui/material';
import { styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { LanguageTag } from '../../../@types/i18next';
import { Logo, useAppSelector } from '../../../shared';
import { ChartSvgIcon } from '../../../shared/assets/images/ChartSvgIcon';
import { TargetSvgIcon } from '../../../shared/assets/images/TargetSvgIcon';
import { ChangeThemeButtonsGroup, LogoutMainMenuButton } from '../../index';
import { MainMenuNestedLinkItem, NestedItem } from './MainMenuNestedLinkItem';
import { MainMenuSingleLinkItem, SingleItem } from './MainMenuSingleLinkItem';

const getDrawerWidth = (language: LanguageTag) => (language === 'ru' ? 324 : 280);

const openedMixin = (theme: Theme, lang: LanguageTag): CSSObject => ({
  width: getDrawerWidth(lang),
  height: '100%',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  height: '100%',
  width: `${theme.spacing(12)}`,
  [theme.breakpoints.up('sm')]: {
    width: `${theme.spacing(12)}`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<{ lang: LanguageTag }>(
  ({ theme, lang }) => ({
    width: getDrawerWidth(lang),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme, lang),
          '& .MuiDrawer-paper': openedMixin(theme, lang),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export const MainMenu = () => {
  const { pathname } = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const open = useAppSelector((state) => state['main-menu'].isOpened);

  const routes: (NestedItem | SingleItem)[] = [
    {
      id: 'Home',
      type: 'single',
      title: t('sidebar_menu_dashboard'),
      path: '/home',
      startIcon: (color) => <TvIcon htmlColor={color} />,
    },
    {
      id: 'Campaigns',
      type: 'single',
      title: t('sidebar_menu_campaigns'),
      path: '/campaigns',
      startIcon: (color) => <TargetSvgIcon stroke={color} />,
    },
    {
      id: 'Statistics',
      type: 'nested',
      title: t('sidebar_menu_statistics'),
      startIcon: (color) => <ChartSvgIcon stroke={color} />,
      childrenItems: [],
      path: '/transactions',
    },
  ];

  return (
    <Drawer
      variant="permanent"
      lang={language as LanguageTag}
      open={open}
      PaperProps={{ sx: { position: 'relative', paddingTop: 3, paddingBottom: 2, paddingX: 2 } }}
    >
      <Stack spacing={5} height={'100%'}>
        <Logo isMiny={!open} />
        <Stack flexGrow={1}>
          <Stack spacing={1}>
            {routes.map((route) => {
              if (route.type === 'nested') {
                return (
                  <MainMenuNestedLinkItem
                    key={route.id}
                    {...route}
                    isMiny={!open}
                    expanded={!!route.childrenItems?.find(({ path }) => path === pathname)}
                    selected={!!route.path && pathname.includes(route.path)}
                  />
                );
              }
              if (route.type === 'single') {
                return (
                  <MainMenuSingleLinkItem
                    key={route.id}
                    {...route}
                    isMiny={!open}
                    selected={pathname.includes(route.path)}
                  />
                );
              }
            })}
            <Divider
              sx={({ spacing }) => ({
                marginY: spacing(2),
              })}
            />
            <LogoutMainMenuButton isMiny={!open} />
          </Stack>
        </Stack>
        <ChangeThemeButtonsGroup isMiny={!open} />
      </Stack>
    </Drawer>
  );
};
