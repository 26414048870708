import React, { SVGProps } from 'react';

export const ChartSvgIcon = (props: SVGProps<SVGPathElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.375 19.5H2.625M4.125 19.5V12.75H9.375M9.375 19.5V8.25H14.625M14.625 3.75H19.875V19.5H14.625V3.75Z"
        stroke="#8498B5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
};
