import { Avatar, FormControl, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageTag } from '../../../@types/i18next';
import { UISelect } from '../../../shared';
import { languages } from '../../../shared/i18n';
import { UIMenuItem } from '../../../shared/ui/UIMenuItem';
import { useChangeLanguage } from '../model/useChangeLanguage';

export const ChangeLanguageSelect = () => {
  const { currentLanguage, changeLanguage } = useChangeLanguage();
  const { t } = useTranslation();

  return (
    <FormControl>
      <UISelect
        value={currentLanguage?.tag}
        sx={{
          '& fieldset': {
            borderWidth: 0,
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderWidth: 0,
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderWidth: 0,
            borderColor: 'transparent',
          },
        }}
        onChange={(e) => changeLanguage(e.target.value as LanguageTag)}
      >
        {languages.map(({ tag, flagImageLink }) => (
          <UIMenuItem key={tag} value={tag}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Avatar src={flagImageLink} alt={tag} sx={{ width: 24, height: 24 }} />
              <Typography variant={'body2'}>{t(`languages.${tag as LanguageTag}`)}</Typography>
            </Stack>
          </UIMenuItem>
        ))}
      </UISelect>
    </FormControl>
  );
};
