import { EntityState } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithAuthControl } from '../../baseQueryWithAuthControl';
import { adAdapter } from './adAdapter';
import { campaignAdapter } from './campaignAdapter';
import { AdDto } from './dto/Ad.dto';
import { CampaignDto } from './dto/Campaign.dto';
import { CreateCampaignRequest } from './dto/CreateCampaignRequest';
import { GetAdsRequest } from './dto/GetAdsRequest';
import { GetCampaignRequest } from './dto/GetCampaignRequest';
import { GetCampaignsListRequest } from './dto/GetCampaignsListRequest';
import { UpdateCampaignRequest } from './dto/UpdateCampaignRequest';

export enum CampaignTags {
  CAMPAIGNS = 'CAMPAIGNS',
  CAMPAIGN = 'CAMPAIGN',
  ADS = 'ADS',
}

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: baseQueryWithAuthControl(),
  tagTypes: Object.values(CampaignTags),
  endpoints: (build) => ({
    getCampaignList: build.query<EntityState<CampaignDto, number>, GetCampaignsListRequest>({
      query: (body) => ({
        url: 'Campaigns/List',
        method: 'POST',
        body,
      }),
      providesTags: [CampaignTags.CAMPAIGNS],
      serializeQueryArgs: ({ queryArgs }) => queryArgs.channelId,
      transformResponse: (baseQueryReturnValue: CampaignDto[]) => {
        return campaignAdapter.addMany(campaignAdapter.getInitialState(), baseQueryReturnValue);
      },
    }),

    getCampaign: build.query<CampaignDto, GetCampaignRequest>({
      query: ({ channelId, id }) => ({
        url: `Campaigns/${channelId}/${id}`,
        method: 'GET',
      }),
      providesTags: [CampaignTags.CAMPAIGN],
      serializeQueryArgs: ({ queryArgs, endpointName }) => endpointName + queryArgs.id,
    }),

    createCampaign: build.mutation<CampaignDto, CreateCampaignRequest>({
      query: (body) => ({
        url: 'Campaigns/Create',
        method: 'POST',
        body,
      }),
      invalidatesTags: [CampaignTags.CAMPAIGNS],
    }),

    updateCampaign: build.mutation<CampaignDto, UpdateCampaignRequest>({
      query: (body) => ({
        url: 'Campaigns/Update',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        const patchCampaigns = dispatch(
          campaignApi.util.updateQueryData(
            'getCampaignList',
            { channelId: Number(args.channelId), startDate: '', endDate: '' },
            (draft) => {
              campaignAdapter.updateOne(draft, {
                id: args.id,
                changes: {
                  ...args,
                },
              });
            },
          ),
        );
        const patchCampaign = dispatch(
          campaignApi.util.updateQueryData('getCampaign', { channelId: args.channelId, id: args.id }, (draft) => {
            draft.name = args.name;
            draft.description = args.description;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchCampaigns.undo();
          patchCampaign.undo();
        }
      },
    }),

    getAds: build.query<EntityState<AdDto, number>, GetAdsRequest>({
      query: ({ channelId, campaignId, ...body }) => ({
        url: `Campaigns/${channelId}/${campaignId}/ads`,
        method: 'POST',
        body,
      }),
      providesTags: [CampaignTags.ADS],
      serializeQueryArgs: ({ queryArgs, endpointName }) => endpointName + queryArgs.campaignId,
      transformResponse: (baseQueryReturnValue: AdDto[]) => {
        return adAdapter.addMany(adAdapter.getInitialState(), baseQueryReturnValue);
      },
    }),
  }),
});

export const {
  useGetCampaignListQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useLazyGetCampaignQuery,
  useGetAdsQuery,
} = campaignApi;
