import { FormControl, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';

import { UISelect } from '../../../../shared';
import { useDeferredComponent } from '../../../../shared/hooks/useDeferredComponent';
import { UIMenuItem } from '../../../../shared/ui/UIMenuItem';
import { useSelectActiveChannel } from '../model/useSelectActiveChannel';

export const ActiveChannelSelect = ({ onChange }: { onChange: () => void }) => {
  const { activeChannelId, selectedChannels, isFetchingChannels, selectActiveChannel } = useSelectActiveChannel();

  const skeleton = useDeferredComponent(isFetchingChannels, <Skeleton variant="rounded" width={190} height={48} />);

  if (skeleton) {
    return skeleton;
  }

  if (!selectedChannels?.ids.length) {
    return null;
  }

  return (
    <FormControl>
      <UISelect
        value={activeChannelId}
        sx={{
          '& fieldset': {
            borderWidth: 0,
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderWidth: 0,
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderWidth: 0,
            borderColor: 'transparent',
          },
        }}
        onChange={(e) => {
          selectActiveChannel(Number(e.target.value));
          onChange();
        }}
      >
        {selectedChannels?.ids.map((id) => (
          <UIMenuItem key={id} value={id}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography
                variant={'body2'}
                dangerouslySetInnerHTML={{ __html: selectedChannels?.entities[id]?.name }}
              />
            </Stack>
          </UIMenuItem>
        ))}
      </UISelect>
    </FormControl>
  );
};
