import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IActiveChannelState {
  id: Nullable<number>;
}

const initialState: IActiveChannelState = { id: null };

const selectActiveChanel = (state: IActiveChannelState, action: PayloadAction<number>): IActiveChannelState => {
  state.id = action.payload;
  return state;
};

const removeActiveChannel = (state: IActiveChannelState): IActiveChannelState => {
  state.id = null;
  return state;
};

export const activeChannelSlice = createSlice({
  name: 'activeChannel',
  reducerPath: 'activeChannel',
  initialState,
  reducers: { selectActiveChanel, removeActiveChannel },
});

export const { selectActiveChanel: selectActiveChanelAction, removeActiveChannel: removeActiveChannelAction } =
  activeChannelSlice.actions;
