import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

import { resetAuthUserAction } from '../../entities';
import { baseQueryWithAuth } from './baseQuery';
import { authApi } from './services';
import { chanelApi, clientApi } from './services';

const activeEndpoints = new Map<string, AbortController>();

export const baseQueryWithAuthControl: (
  baseQuery?: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, NonNullable<unknown>, FetchBaseQueryMeta>,
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  (baseQuery = baseQueryWithAuth) =>
  async (args, api, extraOptions) => {
    const abortController = new AbortController();
    const { signal } = abortController;

    if (activeEndpoints.has(api.endpoint)) {
      activeEndpoints.get(api.endpoint)?.abort();
    }

    activeEndpoints.set(api.endpoint, abortController);

    const result = await baseQuery(args, { ...api, signal }, extraOptions);
    if (result.error && result.error.status === 401 && !api.endpoint.toLowerCase().includes('login')) {
      api.dispatch(resetAuthUserAction());
      api.dispatch(authApi.util.resetApiState());
      api.dispatch(chanelApi.util.resetApiState());
      api.dispatch(clientApi.util.resetApiState());
    }

    activeEndpoints.delete(api.endpoint);

    return result;
  };
