import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { router } from './app/router/router';
import { persistor, store } from './app/store/store';
import i18n from './shared/i18n';

const container = document.getElementById('root');
const root = createRoot(container!); // Используйте восклицательный знак, чтобы указать, что container не будет null
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
);
