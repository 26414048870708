import { MenuItem, MenuItemProps } from '@mui/material';
import React from 'react';

export const UIMenuItem = (props: MenuItemProps) => {
  return (
    <MenuItem
      {...props}
      sx={(theme) => ({
        borderRadius: '20px',
        paddingY: `${theme.spacing(2)} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.background.default} !important`,
        },
      })}
    >
      {props.children}
    </MenuItem>
  );
};
