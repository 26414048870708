import { Stack, Typography } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../store';
import { UiDateRangePicker } from './UIDateRangePicker';
import { UIMenuItem } from './UIMenuItem';
import { UISelect } from './UISelect';

export enum RangeEnum {
  'LAST_WEEK',
  'LAST_MONTH',
  'LAST_THREE_MONTH',
  'CUSTOM',
}

interface IUIDateRangeFilterProps {
  defaultRangeType?: RangeEnum;
  onChangeDate: (value: [Nullable<Date>, Nullable<Date>]) => void;
  startDate: string;
  endDate: string;
}

export const UIDateRangeFilter = memo(
  ({ defaultRangeType, startDate, endDate, onChangeDate }: IUIDateRangeFilterProps) => {
    const { t } = useTranslation();
    const language = useAppSelector((state) => state.system.language);
    const rangeList = useMemo<{ type: RangeEnum; text: string }[]>(
      () => [
        {
          type: RangeEnum.LAST_WEEK,
          text: t('datepicker.date_range_picker_last7days'),
        },
        {
          type: RangeEnum.LAST_MONTH,
          text: t('datepicker.date_range_picker_last30days'),
        },
        {
          type: RangeEnum.LAST_THREE_MONTH,
          text: t('datepicker.date_range_picker_last3months'),
        },
        {
          type: RangeEnum.CUSTOM,
          text: t('datepicker.date_range_picker_custom'),
        },
      ],
      [language],
    );
    const [rangeType, setRangeType] = useState<RangeEnum>(defaultRangeType || RangeEnum.LAST_THREE_MONTH);

    useEffect(() => {
      const now = new Date();
      let end = new Date().toISOString();
      let start: string = '';
      switch (rangeType) {
        case RangeEnum.CUSTOM:
          end = '';
          start = '';
          break;
        case RangeEnum.LAST_WEEK:
          start = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
          break;
        case RangeEnum.LAST_MONTH:
          start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString();
          break;
        case RangeEnum.LAST_THREE_MONTH:
          now.setMonth(now.getMonth() - 3);
          start = now.toISOString();
          break;
      }
      if (!start || !end) return;
      onChangeDate([new Date(start), new Date(end)]);
    }, [rangeType]);

    return (
      <Stack direction="row" spacing={2} flexGrow={1} position={'relative'}>
        <UISelect defaultValue={rangeType} onChange={(e) => setRangeType(e.target.value as RangeEnum)}>
          {rangeList.map((value) => (
            <UIMenuItem key={value.type} value={value.type}>
              <Typography variant={'body1'} color={'text.secondary'} fontSize={14}>
                {value.text}
              </Typography>
            </UIMenuItem>
          ))}
        </UISelect>
        <UiDateRangePicker value={[new Date(startDate), new Date(endDate)]} onChange={onChangeDate} />
      </Stack>
    );
  },
);

UIDateRangeFilter.displayName = 'UIDateRangeFilter';
