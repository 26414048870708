import { setThemeAction, useAppDispatch } from '../../../shared';

export const useChangeTheme = () => {
  const dispatch = useAppDispatch();

  const changeThemeMode = (themeMode: 'dark' | 'light') => {
    dispatch(setThemeAction(themeMode));
  };

  return {
    changeThemeMode,
  };
};
