import { Typography, TypographyProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { darkTheme } from '../themes/theme';

export const UiCheckboxLabel = ({ children, ...props }: PropsWithChildren<TypographyProps>) => {
  return (
    <Typography variant={'body2'} color={darkTheme.palette.text.tertiary} fontSize={16} fontWeight={400} {...props}>
      {children}
    </Typography>
  );
};
