import Paper from '@mui/material/Paper';
import { GridRowsProp } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDate, useTheme } from '../../../shared';
import { UiCheckboxCircle } from '../../../shared/ui/UiCheckboxCircle';
import { UiDataGrid } from '../../../shared/ui/UiDataGrid';
import { getAdsColumns, statuses } from '../constants';
import { useAd } from '../model/useAd';

export const AdsTable = () => {
  const { adData, isFetchingData, searchText, selectedAds, selectAd } = useAd();
  const { t } = useTranslation();
  const { format } = useDate();
  const { isDark } = useTheme();

  const columns = useMemo(() => getAdsColumns(t, format, isDark), [t, format, isDark]);

  const rows: GridRowsProp =
    adData?.ids.map((id) => {
      const item = adData.entities[id];
      return {
        ...item,
        status: statuses[item.status],
      };
    }) || [];

  return (
    <Paper elevation={0} sx={{ paddingY: 2, paddingX: 4, overflow: 'hidden' }}>
      <UiDataGrid
        columns={columns}
        rows={rows}
        rowBufferPx={50}
        autosizeOnMount
        loading={isFetchingData}
        filterModel={{ items: [], quickFilterValues: [searchText] }}
        rowSelectionModel={Array.from(selectedAds)}
        onRowSelectionModelChange={(newSelection) => {
          selectAd(newSelection as number[]);
          console.log(newSelection);
        }}
        hideFooter
        slots={{
          baseCheckbox: UiCheckboxCircle,
        }}
        checkboxSelection
      />
    </Paper>
  );
};
