import SearchIcon from '@mui/icons-material/Search';
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const UiSearchInput = (props: TextFieldProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const timerId = useRef<NodeJS.Timeout>();

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => {
      props.onChange?.(event);
    }, 300);
  };

  return (
    <TextField
      {...props}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <SearchIcon htmlColor={theme.palette.text.secondary} sx={{ marginRight: 1, marginLeft: 1.5, opacity: 0.4 }} />
        ),
      }}
      sx={{
        '.MuiInputBase-input::placeholder': {
          color: theme.palette.text.secondary,
        },
        '.MuiInputBase-input': {
          paddingLeft: '4px',
        },
        ...props.sx,
      }}
      placeholder={t('search')}
      onChange={onChange}
    />
  );
};
