import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Link, Stack, TextField, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RegisterRequestDto, UIErrorFormHelperText } from '../../../shared';
import { PasswordEyeCloseSvgIcon } from '../../../shared/assets/images/PasswordEyeCloseSvgIcon';
import { PasswordEyeSvgIcon } from '../../../shared/assets/images/PasswordEyeSvgIcon';
import { UiCheckboxLabel } from '../../../shared/ui/UICheckboxLabel';
import { UiCheckboxCircle } from '../../../shared/ui/UiCheckboxCircle';
import { useRegister } from '../model/useRegister';

type LoginFormState = RegisterRequestDto & {
  repeatPassword: string;
  privacy: boolean;
  marketing: boolean;
  cookies: boolean;
  terms: boolean;
};

export const RegisterForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<LoginFormState>();
  const { register, isLoadingRegister, registerError } = useRegister();

  const onValid: SubmitHandler<LoginFormState> = (values) => {
    register({ password: values.password, username: values.username });
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={0} alignItems={'center'}>
        <Typography variant={'h5'} fontWeight={700}>
          {t('register_page.title').toUpperCase()}
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: t('errors.required', { name: t('login_page.login') }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <TextField
              value={value}
              placeholder={t('login_page.login')}
              error={invalid}
              helperText={invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
              onChange={onChange}
            />
          )}
          name={'username'}
        />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: t('errors.required', { name: t('login_page.password') }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
            const [showPassword, setShowPassword] = useState(false);
            return (
              <TextField
                value={value}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('login_page.password')}
                error={invalid}
                helperText={invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <PasswordEyeSvgIcon /> : <PasswordEyeCloseSvgIcon />}
                    </IconButton>
                  ),
                }}
              />
            );
          }}
          name={'password'}
        />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: t('errors.requiredField'),
            },
            validate: (value, formValues) => value === formValues.password || t('errors.doNotMatch'),
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
            const [showPassword, setShowPassword] = useState(false);
            return (
              <TextField
                value={value}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('register_page.confirm_password')}
                error={invalid}
                helperText={invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <PasswordEyeSvgIcon /> : <PasswordEyeCloseSvgIcon />}
                    </IconButton>
                  ),
                }}
              />
            );
          }}
          name={'repeatPassword'}
        />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: t('errors.requiredField'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <Box>
              <FormControlLabel
                sx={{ display: 'table' }}
                control={
                  <div style={{ display: 'table-cell' }}>
                    <UiCheckboxCircle value={value} onChange={onChange} />
                  </div>
                }
                label={
                  <UiCheckboxLabel>
                    {t('register_page.i_agree') + ' '}
                    <Link href={'/auth/privacy_policy'} component={'a'}>
                      {t('register_page.privacy_policy')}
                    </Link>
                  </UiCheckboxLabel>
                }
              />
              {invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
            </Box>
          )}
          name={'privacy'}
        />

        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box>
              <FormControlLabel
                sx={{ display: 'table' }}
                control={
                  <div style={{ display: 'table-cell' }}>
                    <UiCheckboxCircle value={value} onChange={onChange} />
                  </div>
                }
                label={<UiCheckboxLabel>{t('register_page.marketing_materials')}</UiCheckboxLabel>}
              />
            </Box>
          )}
          name={'marketing'}
        />

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: t('errors.requiredField'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <Box>
              <FormControlLabel
                sx={{ display: 'table' }}
                control={
                  <div style={{ display: 'table-cell' }}>
                    <UiCheckboxCircle value={value} onChange={onChange} />
                  </div>
                }
                label={<UiCheckboxLabel>{t('register_page.cookie_policy')}</UiCheckboxLabel>}
              />
              {invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
            </Box>
          )}
          name={'cookies'}
        />

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: t('errors.requiredField'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <Box>
              <FormControlLabel
                sx={{ display: 'table' }}
                control={
                  <div style={{ display: 'table-cell' }}>
                    <UiCheckboxCircle value={value} onChange={onChange} />
                  </div>
                }
                label={
                  <UiCheckboxLabel>
                    {t('register_page.i_have_read')}
                    <Link href={'/auth/terms_and_conditions'} component={'a'}>
                      {t('register_page.terms_and_conditions')}
                    </Link>
                  </UiCheckboxLabel>
                }
              />
              {invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
            </Box>
          )}
          name={'terms'}
        />
        {registerError && 'message' in registerError && (
          <UIErrorFormHelperText>{registerError.message}</UIErrorFormHelperText>
        )}
      </Stack>

      <Stack spacing={2}>
        <LoadingButton variant="contained" type={'submit'} loading={isLoadingRegister} onClick={handleSubmit(onValid)}>
          {t('register_page.submit')}
        </LoadingButton>
        <Button variant="contained-secondary" component={Link} href={'/auth/login'}>
          {t('login_page.login')}
        </Button>
      </Stack>
    </Stack>
  );
};
