import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { GridValidRowModel } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { UiCheckboxCircle } from '../../../shared/ui/UiCheckboxCircle';
import { UiDataGrid } from '../../../shared/ui/UiDataGrid';
import { getCampaignsTableColumns } from '../constants';
import { useCampaignList } from '../model/useCampaignList';

export const CampaignListTable = () => {
  const { campaignsListData, searchText, isFetchingCampaignsList } = useCampaignList();
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const columns = useMemo(() => getCampaignsTableColumns(t, theme, searchParams), [t, theme, searchParams]);

  const rows: GridValidRowModel[] =
    campaignsListData?.ids.map((id) => {
      const item = campaignsListData.entities[id];
      return {
        ...item,
      };
    }) || [];

  return (
    <Paper elevation={0} sx={{ paddingY: 2, paddingX: 4, overflow: 'hidden' }}>
      <UiDataGrid
        columns={columns}
        rows={rows}
        hideFooter
        loading={isFetchingCampaignsList}
        filterModel={{ items: [], quickFilterValues: [searchText] }}
        slots={{
          baseCheckbox: UiCheckboxCircle,
        }}
        checkboxSelection
      />
    </Paper>
  );
};
