import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Divider, IconButton, Link, Stack, TextField, Typography } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { BASE_URL, TELEGRAM_BOT_USERNAME } from '../../../constants';
import { LoginRequestDto, UIErrorFormHelperText } from '../../../shared';
import { PasswordEyeCloseSvgIcon } from '../../../shared/assets/images/PasswordEyeCloseSvgIcon';
import { PasswordEyeSvgIcon } from '../../../shared/assets/images/PasswordEyeSvgIcon';
import { required } from '../../../shared/helpers/formValidationRules';
import { useLogin } from '../model/useLogin';

type LoginFormState = LoginRequestDto;

export const LoginForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<LoginFormState>();
  const { login, isLoadingLogin, isLoadingLoginExternal, loginError, triggerLoginExternal } = useLogin();
  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    const id = searchParams.get('id');
    const hash = searchParams.get('hash');
    const auth_date = searchParams.get('auth_date');
    const first_name = searchParams.get('first_name');
    const last_name = searchParams.get('last_name');
    const username = searchParams.get('username');
    const photo_url = searchParams.get('photo_url');
    if (id && first_name && hash && username && auth_date) {
      triggerLoginExternal({
        id,
        hash,
        authDate: auth_date,
        firstName: first_name,
        lastName: last_name,
        username,
        photoUrl: photo_url,
      });
    }
  }, []);

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?7';
    script.setAttribute('data-telegram-login', TELEGRAM_BOT_USERNAME);
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-radius', '20');
    script.setAttribute('data-userpic', 'false');
    script.setAttribute('data-auth-url', `${BASE_URL}/auth/login`);
    script.setAttribute('data-request-access', 'write');
    script.setAttribute('lang', 'en');
    const telegramButton = document.createElement('div');
    telegramButton.setAttribute('id', 'telegram-button');
    telegramButton.appendChild(script);
    const telegramButtonContainer = document.getElementById('telegram-button-container');
    if (telegramButtonContainer?.childNodes.length === 0) {
      telegramButtonContainer?.appendChild(telegramButton);
    }
  }, []);

  const onValid: SubmitHandler<LoginFormState> = (values) => {
    login(values);
  };

  if (isLoadingLoginExternal) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          height: '100vh',
          width: '100vw',
          zIndex: 100,
          left: 0,
          top: 0,
          right: 0,
          backgroundColor: theme.palette.background.default,
        })}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={0} alignItems={'center'}>
        <Typography variant={'h5'} fontWeight={700}>
          {t('login_page.title').toUpperCase()}
        </Typography>
        <Typography variant="subtitle1">{t('login_page.login_and_password')}</Typography>
      </Stack>

      <Stack spacing={2}>
        <Controller
          control={control}
          rules={{
            required: required('login_page.login'),
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <TextField
              value={value}
              placeholder={t('login_page.login')}
              error={invalid}
              helperText={invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
              onChange={onChange}
            />
          )}
          name={'username'}
        />
        <Controller
          control={control}
          rules={{
            required: required('login_page.password'),
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
            const [showPassword, setShowPassword] = useState(false);
            return (
              <TextField
                value={value}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('login_page.password')}
                error={invalid}
                helperText={invalid && <UIErrorFormHelperText>{error?.message}</UIErrorFormHelperText>}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <PasswordEyeSvgIcon /> : <PasswordEyeCloseSvgIcon />}
                    </IconButton>
                  ),
                }}
              />
            );
          }}
          name={'password'}
        />
        {loginError && (
          <UIErrorFormHelperText style={{ marginBottom: -16 }}>{t('errors.loginError')}</UIErrorFormHelperText>
        )}
      </Stack>

      <Stack spacing={2}>
        <LoadingButton variant="contained" type={'submit'} loading={isLoadingLogin} onClick={handleSubmit(onValid)}>
          {t('login_page.login_and_password_submit')}
        </LoadingButton>
        <Button variant="contained-secondary" component={Link} href={'/auth/register'}>
          {t('login_page.register')}
        </Button>
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <Typography variant="subtitle1" align={'center'}>
          {t('login_page.socialMedia')}
        </Typography>
        <Box id={'telegram-button-container'} alignSelf={'center'} />
      </Stack>
    </Stack>
  );
};
