import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ChangeLanguageSelect } from '../../features/change-language/ui/ChangeLanguageSelect';
import { ActiveChannelSelect } from '../../features/channels/select-active-channel/ui/ActiveChannelSelect';
import { ToggleMainMenuButton } from '../../features/main-menu/ui/ToggleMainMenuButton';
import { HeaderTitle } from './HeaderTitle';

export const HeaderWidget = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onChangeChannel = () => {
    const regex = /^\/campaigns\/\d+$/;
    if (regex.test(location.pathname)) {
      navigate({ ...location, pathname: 'campaigns' });
    }
  };

  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Grid item>
        <Stack direction={'row'} spacing={3} alignItems={'flex-end'}>
          <ToggleMainMenuButton />
          <HeaderTitle />
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction={'row'} spacing={location.pathname !== '/home' ? 2 : 0}>
          <ChangeLanguageSelect />
          <Box
            visibility={location.pathname !== '/home' ? 'visible' : 'hidden'}
            width={location.pathname !== '/home' ? 'auto' : 0}
          >
            <ActiveChannelSelect onChange={onChangeChannel} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
