import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { authRoutes, mainRoutes } from '../../pages';
import App from '../App';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      /** AUTH */
      authRoutes,
      /** MAIN */
      mainRoutes,
    ],
  },
]);
