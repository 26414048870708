import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector, useGetAdsQuery } from '../../../shared';
import { selectAdsAction } from './adSlice';

export const useAd = () => {
  const filters = useAppSelector((state) => state.ad.filters);
  const searchText = useAppSelector((state) => state.ad.searchText);
  const selectedAds = useAppSelector((state) => state.ad.selectedAds);
  const channelId = useAppSelector((state) => state.activeChannel.id);
  const { campaignId } = useParams();
  const { data: adData, isFetching: isFetchingData } = useGetAdsQuery(
    {
      ...filters,
      channelId: channelId!.toString(),
      campaignId: campaignId!,
    },
    { refetchOnMountOrArgChange: true },
  );
  const dispatch = useDispatch();

  const selectAd = (ids: number[]) => {
    dispatch(selectAdsAction(Array.from(new Set(ids))));
  };

  return {
    adData,
    searchText,
    isFetchingData,
    selectedAds,
    selectAd,
  };
};
