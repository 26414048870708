import { DailyDashboardDataDto } from '../../../shared';
import { GetDailyDashboardDataRequest } from '../../../shared/api/services/client/dto/GetDailyDashboardDataRequest';

export type DashboardMetricID = keyof Omit<DailyDashboardDataDto, 'date'>;

export type DashboardMetricItem = { id: DashboardMetricID; color: string }[];

export const DashboardMetricList: DashboardMetricItem = [
  {
    id: 'impressions',
    color: '#0072eb',
  },
  {
    color: '#2bd580',
    id: 'clicks',
  },
  {
    color: '#ffd600',
    id: 'subscriptions',
  },
  {
    color: '#ff8785',
    id: 'expenses',
  },
  {
    color: '#29c2f2',
    id: 'cpc',
  },
  {
    color: '#ffa200',
    id: 'ctr',
  },
  {
    color: '#6e5dff',
    id: 'cr',
  },
  {
    color: '#ff88f7',
    id: 'cps',
  },
];

export interface IDashboardState {
  filters: GetDailyDashboardDataRequest;
  selectedMetricIds: DashboardMetricID[];
}

const now = new Date();
const threeMonthAgo = new Date();
threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);

export const dashboardState: IDashboardState = {
  filters: {
    groupIds: [],
    endDate: now.toISOString(),
    startDate: threeMonthAgo.toISOString(),
  },
  selectedMetricIds: DashboardMetricList.map(({ id }) => id),
};
