import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import { Box, Collapse, Menu, Stack, Typography, alpha, useTheme } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UIMenuItem } from '../../../shared/ui/UIMenuItem';
import { MainMenuSingleLinkItem, SingleItem } from './MainMenuSingleLinkItem';

export type NestedItem = {
  id: string;
  title: string;
  type: 'nested';
  startIcon?: (color?: string) => ReactNode;
  expanded?: boolean;
  childrenItems?: SingleItem[];
  isMiny?: boolean;
  // temporary
  path?: string;
  selected?: boolean;
};

export const MainMenuNestedLinkItem = ({
  title,
  startIcon,
  expanded,
  childrenItems,
  isMiny,
  path,
  selected,
}: NestedItem) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(expanded);
  const { palette, spacing } = useTheme();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!childrenItems?.length) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Box
        id="nested-item-button"
        aria-controls={open ? 'nested-miny-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          padding: '15px 20px',
          borderRadius: '16px',
          cursor: 'pointer',
          backgroundColor: selected ? alpha(palette.primary.main, 0.08) : 'transparent',
        }}
        onClick={(e) => (path ? navigate(path) : isMiny ? handleOpenMenu(e) : setOpen((state) => !state))}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems={'center'}
          justifyContent="space-between"
          color={selected ? palette.primary.main : palette.text.secondary}
        >
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>{startIcon?.(palette.text.secondary)}</Box>
            {!isMiny && (
              <Typography variant="body2" fontSize={14} fontWeight={500}>
                {title}
              </Typography>
            )}
          </Stack>
          {!isMiny && (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&::before': {
                  content: '""',
                  display: 'block !important',
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  backgroundColor: palette.icon.pathFill,
                  position: 'absolute',
                  margin: '0 auto',
                },
              }}
            >
              <ExpandCircleDownRoundedIcon
                sx={{
                  fill: palette.icon.rectFill,
                  transition: 'transform 0.2s linear',
                  transform: open ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
              />
            </Box>
          )}
        </Stack>
      </Box>
      {!isMiny ? (
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ paddingLeft: spacing(2) }}>
          {childrenItems?.map((item) => (
            <MainMenuSingleLinkItem key={item.path} {...item} isMiny={isMiny} selected={pathname.includes(item.path)} />
          ))}
        </Collapse>
      ) : (
        <Menu
          id="nested-miny-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: ({ shadows }) => ({
                boxShadow: shadows[16],
                paddingLeft: 2,
              }),
            },
          }}
          MenuListProps={{
            'aria-labelledby': 'nested-item-button',
          }}
        >
          {childrenItems?.map((item) => {
            const selected = pathname.includes(item.path);
            return (
              <UIMenuItem
                key={item.id}
                value={item.id}
                selected={selected}
                sx={{ backgroundColor: item.selected ? alpha(palette.primary.main, 0.08) : 'transparent' }}
                onClick={() => {
                  handleClose();
                  navigate(item.path);
                }}
              >
                <Stack spacing={1} alignItems={'center'} direction={'row'}>
                  {!!item.startIcon && (
                    <Box display={'flex'}>
                      {item.startIcon?.(selected ? palette.primary.main : palette.text.secondary)}
                    </Box>
                  )}
                  <Box>{item.title}</Box>
                </Stack>
              </UIMenuItem>
            );
          })}
        </Menu>
      )}
    </Stack>
  );
};
