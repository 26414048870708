import { IconButton } from '@mui/material';
import React from 'react';

import { useAppDispatch } from '../../../shared';
import { BurgerMenuSvgIcon } from '../../../shared/assets/images/BurgerMenuSvgIcon';
import { toggleMainMenuAction } from '../model/mainMenuSlice';

export const ToggleMainMenuButton = () => {
  const dispatch = useAppDispatch();

  const toggle = () => {
    dispatch(toggleMainMenuAction());
  };

  return (
    <IconButton onClick={toggle}>
      <BurgerMenuSvgIcon width={'24px'} height={'24px'} />
    </IconButton>
  );
};
