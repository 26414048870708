import { GetCampaignsListRequest } from '../../../shared/api/services/campaign/dto/GetCampaignsListRequest';

export interface ICampaignSate {
  campaignsListFilter: GetCampaignsListRequest;
  searchText: string;
}

const now = new Date();
const threeMonthAgo = new Date();
threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);

export const campaignInitialState: ICampaignSate = {
  campaignsListFilter: {
    channelId: 0,
    endDate: now.toISOString(),
    startDate: threeMonthAgo.toISOString(),
  },
  searchText: '',
};
