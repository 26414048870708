import { UserDto } from '../../../shared/api/services/auth/dto/User.dto';

export interface IAuthorizedUserState {
  isAuth: boolean;
  token: string | null;
  userData: Nullable<UserDto>;
}

export const authorizedUserInitialState: IAuthorizedUserState = {
  isAuth: false,
  token: null,
  userData: null,
};
