import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithAuthControl } from '../../baseQueryWithAuthControl';
import { ChannelBudgetDto } from './dto/ChannelBudget.dto';
import { ChannelBudgetRequest } from './dto/ChannelBudgetRequest';
import { DailyDashboardDataDto } from './dto/DailyDashboardData.dto';
import { GetDailyDashboardDataRequest } from './dto/GetDailyDashboardDataRequest';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: baseQueryWithAuthControl(),
  endpoints: (build) => ({
    getDashboardData: build.query<DailyDashboardDataDto[], GetDailyDashboardDataRequest>({
      query: (body) => ({
        url: 'Client/Dashboard',
        method: 'POST',
        body,
      }),
    }),
    getBudgetPie: build.query<ChannelBudgetDto[], ChannelBudgetRequest>({
      query: (body) => ({
        url: 'Client/BudgetPie',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetDashboardDataQuery, useGetBudgetPieQuery } = clientApi;
