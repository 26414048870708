import { createSlice } from '@reduxjs/toolkit';

interface IMainMenuState {
  isOpened: boolean;
}

const mainMenuInitialState: IMainMenuState = {
  isOpened: true,
};

const toggleMainMenu = (state: IMainMenuState) => {
  state.isOpened = !state.isOpened;
};

export const mainMenuSlice = createSlice({
  name: 'main-menu',
  reducerPath: 'main-menu',
  initialState: mainMenuInitialState,
  reducers: { toggleMainMenu },
});

export const { toggleMainMenu: toggleMainMenuAction } = mainMenuSlice.actions;
