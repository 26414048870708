import { Box, Dialog, DialogProps } from '@mui/material';
import React from 'react';

import { UICloseButton } from '../UICloseButton';

export const UIDialogWithCloseBtn = (props: DialogProps) => {
  return (
    <Dialog PaperProps={{ elevation: 0 }} {...props}>
      <Box display={'flex'} justifyContent={'flex-end'} paddingTop={3} paddingBottom={2} paddingX={3}>
        <UICloseButton onClick={(e) => props.onClose?.(e, 'escapeKeyDown')} />
      </Box>
      {props.children}
    </Dialog>
  );
};
