import { EntityState } from '@reduxjs/toolkit';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithAuthControl } from '../../baseQueryWithAuthControl';
import { PaginatedResponse, PaginationParams } from '../../types';
import { CampaignTags, campaignApi } from '../campaign/campaign';
import { channelAdapter } from './adapter/channelAdapter';
import { ChannelDto } from './dto/Channel.dto';
import { CreateAnnouncementRequest } from './dto/CreateAnnouncementRequest';
import { CreateAnnouncementResult } from './dto/CreateAnnouncementResult';
import { StatisticsItem } from './dto/StatisticsItem';
import { TransactionDto } from './dto/Transaction.dto';
import { ValidateChannelBulkRequest } from './dto/ValidateChannelBulkRequest';
import { ValidateChannelBulkResult } from './dto/ValidateChannelBulkResult';
import { GetChanelListResponse } from './dto/responses/GetChanelListResponse';

export enum CHANNEL_TAGS {
  VALIDATION = 'VALIDATION',
}

export const chanelApi = createApi({
  reducerPath: 'chanelApi',
  baseQuery: baseQueryWithAuthControl(),
  tagTypes: Object.values(CHANNEL_TAGS),
  serializeQueryArgs: ({ endpointName }) => endpointName,
  endpoints: (build) => ({
    getChanelList: build.query<EntityState<ChannelDto, number>, void>({
      query: (body) => ({
        url: 'Channel/List',
        method: 'GET',
        body,
      }),
      transformResponse: (baseQueryReturnValue: GetChanelListResponse) =>
        channelAdapter.addMany(channelAdapter.getInitialState(), baseQueryReturnValue),
    }),

    validateChannelBulk: build.query<ValidateChannelBulkResult[], ValidateChannelBulkRequest>({
      query: (body) => ({
        url: 'Channel/ValidateChannelBulk',
        method: 'POST',
        body,
      }),
      providesTags: [CHANNEL_TAGS.VALIDATION],
      keepUnusedDataFor: 0,
    }),

    createAnnouncement: build.mutation<CreateAnnouncementResult, CreateAnnouncementRequest>({
      queryFn: async (body, api, extraOptions) => {
        try {
          const fetch = baseQueryWithAuthControl();
          const res = (await fetch(
            {
              url: 'Channel/CreateAnnouncement',
              method: 'POST',
              body,
            },
            api,
            extraOptions,
          )) as QueryReturnValue<CreateAnnouncementResult>;
          if (res.data?.success) {
            api.dispatch(campaignApi.util.invalidateTags([CampaignTags.ADS]));
            return { data: res?.data, error: undefined };
          }
          return { error: { message: res.data?.message } };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getTransactionsList: build.query<PaginatedResponse<TransactionDto>, PaginationParams & { id: number }>({
      query: ({ id, ...body }) => ({
        url: `Channel/${id}/Transactions`,
        method: 'POST',
        body,
      }),
    }),

    getStatistics: build.query<
      PaginatedResponse<StatisticsItem>,
      PaginationParams & {
        id: number;
        year: number;
        month: number;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `Channel/${id}/monthly-data-page`,
        method: 'POST',
        body,
      }),
    }),

    getStatisticsFull: build.query<
      StatisticsItem[],
      {
        id: number;
        year: number;
        month: number;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `Channel/${id}/monthly-data`,
        method: 'POST',
        body,
      }),
    }),

    getStatisticRanges: build.query<{ year: number; month: number }[], number>({
      query: (id) => ({
        url: `Channel/${id}/available-dates`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetChanelListQuery,
  useLazyValidateChannelBulkQuery,
  useCreateAnnouncementMutation,
  useGetTransactionsListQuery,
  useGetStatisticsQuery,
  useGetStatisticRangesQuery,
  useGetStatisticsFullQuery,
} = chanelApi;
