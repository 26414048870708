import { Box, Stack, Typography, alpha, useTheme } from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type SingleItem = {
  id: string;
  type: 'single';
  title: string;
  path: string;
  startIcon?: (color?: string) => ReactNode;
  selected?: boolean;
  isMiny?: boolean;
};

export const MainMenuSingleLinkItem = ({ title, path, startIcon, selected, isMiny }: PropsWithChildren<SingleItem>) => {
  const theme = useTheme();

  return (
    <Link to={path} color={theme.palette.primary.main} style={{ textDecoration: 'none' }}>
      <Box
        component={'div'}
        sx={({ palette }) => ({
          backgroundColor: selected ? alpha(palette.primary.main, 0.08) : 'transparent',
          padding: '15px 20px',
          borderRadius: '16px',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: palette.action.hover,
          },
        })}
      >
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <Box
            sx={{ display: 'flex', alignItems: 'center' }}
            color={selected ? theme.palette.primary.main : theme.palette.text.secondary}
          >
            {startIcon?.(selected ? theme.palette.primary.main : theme.palette.text.secondary)}
          </Box>
          {!isMiny && (
            <Typography
              variant="body2"
              fontSize={14}
              fontWeight={500}
              color={selected ? theme.palette.primary.main : theme.palette.text.secondary}
            >
              {title}
            </Typography>
          )}
        </Stack>
      </Box>
    </Link>
  );
};
