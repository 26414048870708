import { useGetCampaignListQuery } from '../../../shared';
import { useAppSelector } from '../../../shared';

export const useCampaignList = () => {
  const filters = useAppSelector((state) => state.campaign.campaignsListFilter);
  const searchText = useAppSelector((state) => state.campaign.searchText);
  const { data, isFetching } = useGetCampaignListQuery(filters, { refetchOnMountOrArgChange: true });

  return {
    campaignsListData: data,
    isFetchingCampaignsList: isFetching,
    searchText,
  };
};
