import { Box, Container, Grid, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { ChangeLanguageSelect } from '../../features/change-language/ui/ChangeLanguageSelect';
import { Logo } from '../../shared';
import { AuthBackgroundText } from '../../shared/assets/images/AuthBackgroundText';

export const AuthPagesLayout = () => {
  return (
    <Container sx={{ height: '100vh', flexDirection: 'column', position: 'relative' }} maxWidth={false}>
      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          width: 'calc(100vw - 48px)',
          display: 'flex',
          justifyContent: 'center',
          zIndex: -1,
        }}
      >
        <AuthBackgroundText />
      </Box>
      <Box
        paddingY={2}
        paddingX={4}
        display={'flex'}
        justifyContent={'flex-end'}
        position={'fixed'}
        top={0}
        right={0}
        left={0}
      >
        <ChangeLanguageSelect />
      </Box>
      <Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '100vh' }}>
        <Grid
          item
          container
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          maxWidth={'500px'}
          width={'100%'}
        >
          <Stack spacing={2} width={'100%'} paddingY={9} overflow={'auto'}>
            <Box alignSelf={'center'}>
              <Logo />
            </Box>
            <Paper elevation={0}>
              <Box paddingX={8} paddingY={6}>
                <Outlet />
              </Box>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
