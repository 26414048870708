import { DialogProps, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TFuncKeyApp } from '../../i18n';
import { darkTheme } from '../../themes/theme';
import { UIDialogWithCloseBtn } from './UIDialogWithCloseBtn';

interface UIDialogProps extends DialogProps {
  titleKey: TFuncKeyApp;
  subTitleKey?: TFuncKeyApp;
}

export const UIDialog = ({ children, titleKey, subTitleKey, ...props }: UIDialogProps) => {
  const { t } = useTranslation();

  return (
    <UIDialogWithCloseBtn disableRestoreFocus {...props} maxWidth="sm" fullWidth>
      <Stack spacing={3} paddingX={6} paddingBottom={6}>
        <Stack spacing={1}>
          <Typography variant="h5" fontWeight={700} textAlign={'center'}>
            {t(titleKey).toString().toUpperCase()}
          </Typography>
          {subTitleKey && (
            <Typography variant="body1" color={darkTheme.palette.text.tertiary} textAlign={'center'}>
              {t(subTitleKey).toString()}
            </Typography>
          )}
        </Stack>
        {children}
      </Stack>
    </UIDialogWithCloseBtn>
  );
};
