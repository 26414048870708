import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithAuthControl } from '../../baseQueryWithAuthControl';
import { LoginExternalRequestDto } from './dto/LoginExternalRequest.dto';
import { LoginRequestDto } from './dto/LoginRequest.dto';
import { LoginResponseDto } from './dto/LoginResponse.dto';
import { RegisterRequestDto } from './dto/RegisterRequest.dto';
import { RegisterResponseDto } from './dto/RegisterResponse.dto';
import { UserDto } from './dto/User.dto';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithAuthControl(),
  serializeQueryArgs: ({ endpointName }) => endpointName,
  endpoints: (build) => ({
    login: build.mutation<LoginResponseDto, LoginRequestDto>({
      query: (body) => ({
        url: 'Auth/Login/password',
        method: 'POST',
        body,
      }),
    }),
    loginExternal: build.mutation<LoginResponseDto, LoginExternalRequestDto>({
      query: (body) => ({
        url: 'Auth/Login',
        method: 'POST',
        body,
      }),
    }),
    getUserData: build.query<UserDto, void>({
      query: (body) => ({
        url: 'Auth/GetUserData',
        method: 'GET',
        body,
      }),
    }),
    register: build.mutation<RegisterResponseDto, RegisterRequestDto>({
      queryFn: async (body, api, extraOptions) => {
        try {
          const fetch = baseQueryWithAuthControl();
          const res = (await fetch(
            {
              url: 'Auth/Register',
              method: 'POST',
              body,
            },
            api,
            extraOptions,
          )) as QueryReturnValue<RegisterResponseDto, unknown, unknown>;
          if (res.data?.isSuccess) {
            return { data: res?.data, error: undefined };
          }
          return { error: { message: res.data?.message } };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useLoginExternalMutation, useGetUserDataQuery } = authApi;
