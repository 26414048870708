import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import React from 'react';

import { FormatFn } from '../../../shared';
import { StatusEnum } from '../../../shared/api/services/StatusEnum';
import { baseColSettings } from '../../../shared/constants';
import { numberWithSpaces } from '../../../shared/helpers/numberWithSpaces';
import { numberWithThreeFixedDecimals } from '../../../shared/helpers/numberWithThreeFixedDecimals';

export const statuses: Record<
  StatusEnum,
  { name: string; dark: { backColor: string; color: string }; light: { backColor: string; color: string } }
> = {
  [StatusEnum.none]: {
    name: 'none',
    dark: { backColor: '#303030', color: '#6C7F99' },
    light: { backColor: '#dadada', color: '#7486a1' },
  },
  [StatusEnum.activ]: {
    name: 'activ',
    dark: { backColor: '#152f1a', color: '#1DBC71' },
    light: { backColor: '#cfffe8', color: '#1ebd73' },
  },
  [StatusEnum.onhold]: {
    name: 'onhold',
    dark: { backColor: '#4d4004', color: '#BC901D' },
    light: { backColor: '#fff5d0', color: '#bc901b' },
  },
  [StatusEnum.stopped]: {
    name: 'stopped',
    dark: { backColor: '#300c0d', color: '#BC1D1D' },
    light: { backColor: '#ffcfcf', color: '#bb1e1b' },
  },
  [StatusEnum.inreview]: {
    name: 'interview',
    dark: { backColor: '#040e20', color: '#1D40BC' },
    light: { backColor: '#cedfff', color: '#2950c0' },
  },
  [StatusEnum.remove]: {
    name: 'remove',
    dark: { backColor: '#2f140d', color: '#BC561D' },
    light: { backColor: '#ffddcf', color: '#bc571f' },
  },
  [StatusEnum.nobudget]: {
    name: 'nobudget',
    dark: { backColor: '#150826', color: '#801DBC' },
    light: { backColor: '#e7cfff', color: '#872fc4' },
  },
};

export const getAdsColumns = (t: TFunction, format: FormatFn, isDark: boolean): GridColDef[] => [
  {
    field: 'id',
    headerName: 'id',
    type: 'string',
    ...baseColSettings,
  },
  {
    field: 'name',
    headerName: t('name'),
    type: 'string',
    ...baseColSettings,
    minWidth: 300,
    renderCell: (params) => (
      <Box display={'flex'} alignItems={'center'} dangerouslySetInnerHTML={{ __html: params.row.name }} />
    ),
  },
  {
    field: 'status',
    headerName: t('status'),
    type: 'number',
    minWidth: 100,
    ...baseColSettings,
    renderCell: (params) => (
      <Chip
        size={'medium'}
        label={params.row.status.name}
        sx={{
          backgroundColor: isDark ? params.row.status.dark.backColor : params.row.status.light.backColor,
          color: isDark ? params.row.status.dark.color : params.row.status.light.color,
        }}
      />
    ),
  },
  {
    field: 'cpm',
    headerName: t('create_ad_settings_cpm'),
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'budget',
    headerName: t('budget') as string,
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'impressions',
    headerName: t('impressions'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'clicks',
    headerName: t('clicks'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'subscriptions',
    headerName: t('subscriptions'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'ctr',
    headerName: t('ctr'),
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'cpc',
    headerName: t('cpc'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'cr',
    headerName: t('cr'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'cps',
    headerName: t('cps'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'expenses',
    headerName: t('expenses'),
    type: 'number',
    valueFormatter: (value) => numberWithSpaces(numberWithThreeFixedDecimals(value)),
    ...baseColSettings,
  },
  {
    field: 'date',
    headerName: t('date'),
    type: 'date',
    valueFormatter: (value) => format(value as string, 'yyyy.MM.dd HH:MM:SS'),
    ...baseColSettings,
    minWidth: 200,
  },
];
