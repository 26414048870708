import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithAuthControl } from '../../baseQueryWithAuthControl';
import { adAdapter } from '../campaign/adAdapter';
import { campaignApi } from '../campaign/campaign';
import { UpdateAdStatusRequest } from './dto/UpdateAdStatusRequest';

export const adsApi = createApi({
  reducerPath: 'adsApi',
  baseQuery: baseQueryWithAuthControl(),
  endpoints: (build) => ({
    updateAdsStatus: build.mutation<undefined, UpdateAdStatusRequest>({
      query: (body) => ({
        url: 'Ads/UpdateStatus',
        method: 'POST',
        body,
      }),
      onQueryStarted: async ({ companyId, channelId, status, adIds }, { queryFulfilled, dispatch }) => {
        const patch = dispatch(
          campaignApi.util.updateQueryData(
            'getAds',
            {
              channelId: channelId.toString(),
              campaignId: companyId.toString(),
              startDate: '',
              endDate: '',
              status: null,
            },
            (draft) => {
              return adAdapter.updateMany(
                draft,
                adIds.map((id) => ({ id, changes: { status } })),
              );
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
    }),
  }),
});

export const { useUpdateAdsStatusMutation } = adsApi;
