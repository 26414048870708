import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DashboardMetricItem, IDashboardState, dashboardState } from './dashboardState';

const reset = (state: IDashboardState) => {
  state = dashboardState;

  return state;
};

const setDashboardFilters = (state: IDashboardState, action: PayloadAction<Partial<IDashboardState['filters']>>) => {
  state.filters = { ...state.filters, ...action.payload };

  return state;
};

const addChannelToFilters = (state: IDashboardState, action: PayloadAction<number>) => {
  state.filters.groupIds.push(action.payload.toString());

  return state;
};

const removeChannelFromFilters = (state: IDashboardState, action: PayloadAction<number>) => {
  state.filters.groupIds = state.filters.groupIds.filter((id) => id !== action.payload.toString());

  return state;
};

const removeAllChannelsFromFilters = (state: IDashboardState) => {
  state.filters.groupIds.splice(0);

  return state;
};

const selectMetricId = (state: IDashboardState, action: PayloadAction<DashboardMetricItem[number]['id']>) => {
  state.selectedMetricIds = [...state.selectedMetricIds, action.payload];

  return state;
};

const removeMetricId = (state: IDashboardState, action: PayloadAction<DashboardMetricItem[number]['id']>) => {
  state.selectedMetricIds = [...state.selectedMetricIds.filter((id) => id !== action.payload)];

  return state;
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  reducerPath: 'dashboard',
  initialState: dashboardState,
  reducers: {
    setDashboardFilters,
    selectMetricId,
    removeMetricId,
    addChannelToFilters,
    removeChannelFromFilters,
    removeAllChannelsFromFilters,
    reset,
  },
});

export const {
  setDashboardFilters: setDashboardFiltersAction,
  selectMetricId: selectMetricIdAction,
  removeMetricId: removeMetricIdAction,
  addChannelToFilters: addChannelToFiltersAction,
  removeChannelFromFilters: removeChannelFromFiltersAction,
  removeAllChannelsFromFilters: removeAllChannelsFromFiltersAction,
  reset: resetDashboardSlice,
} = dashboardSlice.actions;
