import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetAdsRequest } from '../../../shared/api/services/campaign/dto/GetAdsRequest';
import { IAdState, adInitialState } from './adState';

const setAdListFilters = (
  state: IAdState,
  action: PayloadAction<Partial<Omit<GetAdsRequest, 'channelId' | 'campaignId'>>>,
) => {
  state.filters = { ...state.filters, ...action.payload };
};

const setSearchText = (state: IAdState, action: PayloadAction<string>) => {
  state.searchText = action.payload;
};

const selectAds = (state: IAdState, action: PayloadAction<number[]>) => {
  state.selectedAds = action.payload;
};

export const adSlice = createSlice({
  name: 'ad',
  reducerPath: 'ad',
  initialState: adInitialState,
  reducers: { setAdListFilters, setSearchText, selectAds },
});

export const {
  setAdListFilters: setAdListFiltersAction,
  setSearchText: setSearchTextAction,
  selectAds: selectAdsAction,
} = adSlice.actions;
