import { useMemo } from 'react';

import { useGetChanelListQuery } from '../../../shared';
import { useAppSelector } from '../../../shared';
import { channelAdapter } from '../../../shared/api/services/channel/adapter/channelAdapter';

export const useChannels = () => {
  const {
    data: channels,
    isFetching: isFetchingChannels,
    isUninitialized: isUninitializedChannels,
    error: channelsError,
  } = useGetChanelListQuery(undefined);
  const selectedChannelsIds = useAppSelector((state) => state.channels.selectedChannelIds);
  const selectedChannels = useMemo(() => {
    const entities = channelAdapter.getSelectors().selectEntities(channels || channelAdapter.getInitialState());
    const selected = selectedChannelsIds.map((id) => entities[id]);
    return channelAdapter.addMany(channelAdapter.getInitialState(), selected);
  }, [selectedChannelsIds]);

  return {
    channels,
    selectedChannels,
    isFetchingChannels,
    isUninitializedChannels,
    channelsError,
    selectedChannelsIds,
  };
};
