import { RegisterRequestDto, useRegisterMutation } from '../../../shared';

export const useRegister = () => {
  const [triggerRegister, { isLoading: isLoadingRegister, error: registerError }] = useRegisterMutation();

  const register = (request: RegisterRequestDto) => {
    triggerRegister(request);
  };

  return {
    register,
    isLoadingRegister,
    registerError,
  };
};
