import { SvgIconProps } from '@mui/material';
import React from 'react';

export const AuthBackgroundText = (props: SvgIconProps) => {
  return (
    <svg width="1918" height="184" viewBox="0 0 1918 184" fill="none" {...props}>
      <path
        d="M185.031 206.824C185.031 213.198 182.694 218.615 178.02 223.077C173.559 227.538 168.142 229.769 161.768 229.769C156.882 229.769 152.421 228.388 148.385 225.626C144.561 222.652 141.905 218.934 140.418 214.473L131.814 189.615H36.2163L27.6124 214.473C26.1254 218.934 23.3636 222.652 19.3273 225.626C15.2909 228.388 10.8297 229.769 5.94354 229.769C-0.429663 229.769 -5.84689 227.538 -10.3081 223.077C-14.7694 218.615 -17 213.198 -17 206.824C-17 205.337 -16.4689 202.681 -15.4067 198.857L46.7321 38.2418C49.7062 30.5934 54.5923 24.4322 61.3904 19.7583C68.1885 15.0843 75.7302 12.7473 84.0153 12.7473C92.3005 12.7473 99.8421 15.0843 106.64 19.7583C113.438 24.4322 118.324 30.5934 121.299 38.2418L183.119 198.857C184.393 201.832 185.031 204.487 185.031 206.824ZM50.2373 150.418H117.793L84.0153 54.1758L50.2373 150.418Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M358.616 33.1429V209.374C358.616 215.11 356.598 220.103 352.561 224.352C348.737 228.388 343.957 230.407 338.222 230.407C332.698 230.407 327.918 228.388 323.882 224.352C319.846 220.315 317.721 215.429 317.509 209.692V208.418C304.762 223.714 289.042 231.363 270.347 231.363C250.378 231.363 234.02 224.139 221.273 209.692C208.527 195.245 202.154 175.593 202.154 150.736C202.154 126.304 208.527 106.758 221.273 92.0989C234.02 77.2271 250.378 69.7912 270.347 69.7912C289.467 69.7912 305.293 77.652 317.827 93.3736V33.1429C317.827 27.6191 319.846 22.8388 323.882 18.8022C327.918 14.7656 332.698 12.7473 338.222 12.7473C343.745 12.7473 348.525 14.7656 352.561 18.8022C356.598 22.8388 358.616 27.6191 358.616 33.1429ZM317.827 177.824V123.33C314.428 118.231 309.436 114.088 302.85 110.901C296.265 107.502 289.573 105.802 282.775 105.802C271.303 105.802 261.956 109.945 254.733 118.231C247.51 126.516 243.898 137.352 243.898 150.736C243.898 163.908 247.51 174.637 254.733 182.923C261.956 191.209 271.303 195.352 282.775 195.352C289.573 195.352 296.265 193.758 302.85 190.571C309.436 187.172 314.428 182.923 317.827 177.824Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M388.869 201.725C388.869 197.051 390.462 193.121 393.649 189.934C397.048 186.535 401.084 184.835 405.758 184.835C408.732 184.835 411.6 185.579 414.361 187.066C428.383 195.777 442.297 200.132 456.106 200.132C473.314 200.132 481.917 194.927 481.917 184.516C481.917 179.842 479.474 176.231 474.588 173.681C469.915 170.919 462.054 168.476 451.007 166.352C438.473 164.227 428.276 161.678 420.416 158.703C400.447 152.117 390.462 138.626 390.462 118.231C390.462 104.634 396.198 93.1612 407.67 83.8132C419.141 74.4652 434.437 69.7912 453.557 69.7912C472.464 69.7912 489.353 74.359 504.224 83.4945C509.535 86.2564 512.19 90.718 512.19 96.8791C512.19 101.128 510.597 104.846 507.41 108.033C504.436 111.007 500.825 112.495 496.576 112.495C493.389 112.495 490.84 111.857 488.928 110.582C478.306 103.996 466.622 100.703 453.875 100.703C446.44 100.703 440.492 101.978 436.03 104.527C431.782 107.077 429.657 110.582 429.657 115.044C429.657 119.293 431.782 122.586 436.03 124.923C440.492 127.26 448.139 129.491 458.974 131.615C469.808 133.527 480.112 136.183 489.884 139.582C510.491 147.018 520.794 161.147 520.794 181.967C520.794 196.414 514.846 208.311 502.949 217.659C491.052 226.795 474.801 231.363 454.194 231.363C432.738 231.363 413.83 226.37 397.473 216.385C391.737 213.198 388.869 208.311 388.869 201.725Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M635.372 212.242C635.372 220.315 631.336 225.626 623.263 228.176C617.527 230.3 610.517 231.363 602.232 231.363C587.998 231.363 577.164 227.751 569.728 220.527C562.293 213.304 558.575 202.894 558.575 189.297V108.989H548.697C543.811 108.989 539.562 107.289 535.95 103.89C532.551 100.491 530.852 96.348 530.852 91.4615C530.852 86.5751 532.551 82.4322 535.95 79.033C539.562 75.4213 543.811 73.6154 548.697 73.6154H558.575V49.7143C558.575 44.1905 560.593 39.4103 564.63 35.3736C568.666 31.337 573.552 29.3187 579.288 29.3187C584.812 29.3187 589.485 31.337 593.309 35.3736C597.346 39.4103 599.364 44.1905 599.364 49.7143V73.6154H615.297C620.183 73.6154 624.325 75.4213 627.724 79.033C631.124 82.4322 632.823 86.5751 632.823 91.4615C632.823 96.1355 631.124 100.278 627.724 103.89C624.325 107.289 620.183 108.989 615.297 108.989H599.364V178.462C599.364 183.56 600.532 187.703 602.869 190.89C605.418 193.864 608.817 195.352 613.066 195.352C614.128 195.352 615.403 195.245 616.89 195.033C618.377 194.821 619.333 194.714 619.758 194.714C624.432 194.927 628.149 196.733 630.911 200.132C633.885 203.319 635.372 207.355 635.372 212.242Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M791.162 128.11V208.736C791.162 214.473 789.144 219.465 785.108 223.714C781.284 227.751 776.504 229.769 770.768 229.769C765.669 229.769 761.102 228.07 757.066 224.67C753.242 221.271 751.011 216.916 750.374 211.604C739.539 224.777 723.394 231.363 701.937 231.363C687.916 231.363 675.489 226.795 664.654 217.659C653.82 208.524 648.403 196.095 648.403 180.374C648.403 164.44 653.714 152.33 664.336 144.044C674.958 135.546 687.492 131.297 701.937 131.297C724.244 131.297 740.495 137.564 750.693 150.099V128.747C750.693 120.886 747.612 114.619 741.451 109.945C735.291 105.271 727.112 102.934 716.914 102.934C705.23 102.934 693.865 106.333 682.818 113.132C680.481 114.619 677.826 115.363 674.851 115.363C670.603 115.363 666.991 113.875 664.017 110.901C661.043 107.714 659.556 103.996 659.556 99.7473C659.556 93.7985 661.999 89.337 666.885 86.3626C683.668 75.315 702.681 69.7912 723.925 69.7912C743.894 69.7912 760.04 74.359 772.361 83.4945C784.895 92.8425 791.162 107.714 791.162 128.11ZM750.693 190.571V171.451C744.107 162.527 733.272 158.066 718.189 158.066C709.692 158.066 702.681 160.19 697.158 164.44C691.847 168.689 689.191 174.319 689.191 181.33C689.191 188.128 691.847 193.652 697.158 197.901C702.681 201.938 709.692 203.956 718.189 203.956C733.272 203.956 744.107 199.495 750.693 190.571Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M916.067 212.242C916.067 220.315 912.031 225.626 903.958 228.176C898.222 230.3 891.212 231.363 882.927 231.363C868.693 231.363 857.859 227.751 850.423 220.527C842.988 213.304 839.27 202.894 839.27 189.297V108.989H829.392C824.506 108.989 820.257 107.289 816.645 103.89C813.246 100.491 811.547 96.348 811.547 91.4615C811.547 86.5751 813.246 82.4322 816.645 79.033C820.257 75.4213 824.506 73.6154 829.392 73.6154H839.27V49.7143C839.27 44.1905 841.288 39.4103 845.325 35.3736C849.361 31.337 854.247 29.3187 859.983 29.3187C865.506 29.3187 870.18 31.337 874.004 35.3736C878.04 39.4103 880.059 44.1905 880.059 49.7143V73.6154H895.992C900.878 73.6154 905.02 75.4213 908.419 79.033C911.818 82.4322 913.518 86.5751 913.518 91.4615C913.518 96.1355 911.818 100.278 908.419 103.89C905.02 107.289 900.878 108.989 895.992 108.989H880.059V178.462C880.059 183.56 881.227 187.703 883.564 190.89C886.113 193.864 889.512 195.352 893.761 195.352C894.823 195.352 896.098 195.245 897.585 195.033C899.072 194.821 900.028 194.714 900.453 194.714C905.127 194.927 908.844 196.733 911.606 200.132C914.58 203.319 916.067 207.355 916.067 212.242Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M1021.46 203.319V38.8791C1021.46 31.2308 1023.48 25.3883 1027.52 21.3517C1031.77 17.1026 1037.72 14.978 1045.36 14.978H1105.27C1138.62 14.978 1165.71 24.8571 1186.53 44.6154C1207.56 64.1612 1218.08 89.7619 1218.08 121.418C1218.08 153.073 1207.56 178.674 1186.53 198.22C1165.71 217.766 1138.62 227.538 1105.27 227.538H1045.36C1037.72 227.538 1031.77 225.52 1027.52 221.484C1023.48 217.234 1021.46 211.179 1021.46 203.319ZM1066.71 187.703H1105.27C1125.24 187.703 1141.28 181.33 1153.39 168.582C1165.71 155.835 1171.87 140.114 1171.87 121.418C1171.87 101.872 1166.03 85.9377 1154.35 73.6154C1142.45 61.0806 1126.09 54.8132 1105.27 54.8132H1066.71V187.703Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M1252.1 41.4286C1247.43 36.5421 1245.09 30.8059 1245.09 24.2198C1245.09 17.6337 1247.43 12.0037 1252.1 7.32967C1256.99 2.44322 1262.72 0 1269.31 0C1276.11 0 1281.84 2.337 1286.52 7.01099C1291.19 11.685 1293.53 17.4213 1293.53 24.2198C1293.53 30.8059 1291.19 36.5421 1286.52 41.4286C1281.84 46.1026 1276.11 48.4396 1269.31 48.4396C1262.72 48.4396 1256.99 46.1026 1252.1 41.4286ZM1249.23 209.374V91.7802C1249.23 86.2564 1251.15 81.4762 1254.97 77.4396C1259.01 73.4029 1263.89 71.3846 1269.63 71.3846C1275.15 71.3846 1279.83 73.4029 1283.65 77.4396C1287.69 81.4762 1289.7 86.2564 1289.7 91.7802V209.374C1289.7 214.897 1287.69 219.678 1283.65 223.714C1279.61 227.751 1274.83 229.769 1269.31 229.769C1263.79 229.769 1259.01 227.751 1254.97 223.714C1251.15 219.678 1249.23 214.897 1249.23 209.374Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M1476.1 91.7802V217.022C1476.1 243.366 1467.92 262.169 1451.56 273.429C1435.42 284.476 1415.87 290 1392.93 290C1372.11 290 1354.05 285.22 1338.76 275.659C1333.23 272.473 1330.47 267.692 1330.47 261.319C1330.47 256.645 1332.06 252.608 1335.25 249.209C1338.65 246.022 1342.69 244.429 1347.36 244.429C1350.12 244.429 1352.99 245.278 1355.96 246.978C1365.95 253.564 1378.27 256.857 1392.93 256.857C1405.25 256.857 1415.34 253.67 1423.2 247.297C1431.27 240.923 1435.31 231.15 1435.31 217.978V202.681C1422.14 218.615 1406.31 226.582 1387.83 226.582C1367.65 226.582 1351.29 219.678 1338.76 205.868C1326.22 191.846 1319.95 172.619 1319.95 148.187C1319.95 123.967 1326.22 104.846 1338.76 90.8242C1351.29 76.8022 1367.65 69.7912 1387.83 69.7912C1406.95 69.7912 1422.78 77.652 1435.31 93.3736V90.8242C1435.31 85.5128 1437.33 80.9451 1441.36 77.1209C1445.61 73.2967 1450.5 71.3846 1456.02 71.3846C1461.55 71.3846 1466.22 73.4029 1470.04 77.4396C1474.08 81.4762 1476.1 86.2564 1476.1 91.7802ZM1435.31 173.044V123.33C1431.91 118.231 1426.92 114.088 1420.33 110.901C1413.96 107.502 1407.37 105.802 1400.58 105.802C1388.89 105.802 1379.44 109.733 1372.21 117.593C1365.2 125.242 1361.7 135.44 1361.7 148.187C1361.7 160.934 1365.2 171.238 1372.21 179.099C1379.44 186.747 1388.89 190.571 1400.58 190.571C1407.16 190.571 1413.75 188.872 1420.33 185.473C1426.92 182.073 1431.91 177.93 1435.31 173.044Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M1519.1 41.4286C1514.43 36.5421 1512.09 30.8059 1512.09 24.2198C1512.09 17.6337 1514.43 12.0037 1519.1 7.32967C1523.99 2.44322 1529.73 0 1536.31 0C1543.11 0 1548.85 2.337 1553.52 7.01099C1558.19 11.685 1560.53 17.4213 1560.53 24.2198C1560.53 30.8059 1558.19 36.5421 1553.52 41.4286C1548.85 46.1026 1543.11 48.4396 1536.31 48.4396C1529.73 48.4396 1523.99 46.1026 1519.1 41.4286ZM1516.24 209.374V91.7802C1516.24 86.2564 1518.15 81.4762 1521.97 77.4396C1526.01 73.4029 1530.89 71.3846 1536.63 71.3846C1542.15 71.3846 1546.83 73.4029 1550.65 77.4396C1554.69 81.4762 1556.71 86.2564 1556.71 91.7802V209.374C1556.71 214.897 1554.69 219.678 1550.65 223.714C1546.62 227.751 1541.84 229.769 1536.31 229.769C1530.79 229.769 1526.01 227.751 1521.97 223.714C1518.15 219.678 1516.24 214.897 1516.24 209.374Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M1681.6 212.242C1681.6 220.315 1677.56 225.626 1669.49 228.176C1663.75 230.3 1656.74 231.363 1648.46 231.363C1634.22 231.363 1623.39 227.751 1615.95 220.527C1608.52 213.304 1604.8 202.894 1604.8 189.297V108.989H1594.92C1590.04 108.989 1585.79 107.289 1582.18 103.89C1578.78 100.491 1577.08 96.348 1577.08 91.4615C1577.08 86.5751 1578.78 82.4322 1582.18 79.033C1585.79 75.4213 1590.04 73.6154 1594.92 73.6154H1604.8V49.7143C1604.8 44.1905 1606.82 39.4103 1610.86 35.3736C1614.89 31.337 1619.78 29.3187 1625.51 29.3187C1631.04 29.3187 1635.71 31.337 1639.54 35.3736C1643.57 39.4103 1645.59 44.1905 1645.59 49.7143V73.6154H1661.52C1666.41 73.6154 1670.55 75.4213 1673.95 79.033C1677.35 82.4322 1679.05 86.5751 1679.05 91.4615C1679.05 96.1355 1677.35 100.278 1673.95 103.89C1670.55 107.289 1666.41 108.989 1661.52 108.989H1645.59V178.462C1645.59 183.56 1646.76 187.703 1649.1 190.89C1651.64 193.864 1655.04 195.352 1659.29 195.352C1660.35 195.352 1661.63 195.245 1663.12 195.033C1664.6 194.821 1665.56 194.714 1665.98 194.714C1670.66 194.927 1674.38 196.733 1677.14 200.132C1680.11 203.319 1681.6 207.355 1681.6 212.242Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M1837.39 128.11V208.736C1837.39 214.473 1835.37 219.465 1831.33 223.714C1827.51 227.751 1822.73 229.769 1816.99 229.769C1811.9 229.769 1807.33 228.07 1803.29 224.67C1799.47 221.271 1797.24 216.916 1796.6 211.604C1785.77 224.777 1769.62 231.363 1748.16 231.363C1734.14 231.363 1721.72 226.795 1710.88 217.659C1700.05 208.524 1694.63 196.095 1694.63 180.374C1694.63 164.44 1699.94 152.33 1710.56 144.044C1721.18 135.546 1733.72 131.297 1748.16 131.297C1770.47 131.297 1786.72 137.564 1796.92 150.099V128.747C1796.92 120.886 1793.84 114.619 1787.68 109.945C1781.52 105.271 1773.34 102.934 1763.14 102.934C1751.46 102.934 1740.09 106.333 1729.04 113.132C1726.71 114.619 1724.05 115.363 1721.08 115.363C1716.83 115.363 1713.22 113.875 1710.24 110.901C1707.27 107.714 1705.78 103.996 1705.78 99.7473C1705.78 93.7985 1708.23 89.337 1713.11 86.3626C1729.89 75.315 1748.91 69.7912 1770.15 69.7912C1790.12 69.7912 1806.27 74.359 1818.59 83.4945C1831.12 92.8425 1837.39 107.714 1837.39 128.11ZM1796.92 190.571V171.451C1790.33 162.527 1779.5 158.066 1764.42 158.066C1755.92 158.066 1748.91 160.19 1743.38 164.44C1738.07 168.689 1735.42 174.319 1735.42 181.33C1735.42 188.128 1738.07 193.652 1743.38 197.901C1748.91 201.938 1755.92 203.956 1764.42 203.956C1779.5 203.956 1790.33 199.495 1796.92 190.571Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
      <path
        d="M1877.53 209.374V33.1429C1877.53 27.6191 1879.44 22.8388 1883.27 18.8022C1887.3 14.7656 1892.19 12.7473 1897.92 12.7473C1903.45 12.7473 1908.12 14.7656 1911.95 18.8022C1915.98 22.8388 1918 27.6191 1918 33.1429V209.374C1918 214.897 1915.98 219.678 1911.95 223.714C1908.12 227.751 1903.45 229.769 1897.92 229.769C1892.19 229.769 1887.3 227.751 1883.27 223.714C1879.44 219.678 1877.53 214.897 1877.53 209.374Z"
        fill="#1C1E29"
        fillOpacity="0.05"
      />
    </svg>
  );
};
