import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageTag } from '../../../@types/i18next';
import { changeLanguageAsyncThunk, useAppDispatch } from '../../../shared';
import { languages } from '../../../shared/i18n';

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();
  const currentLanguage = useMemo(() => languages.find(({ tag }) => i18n.language === tag), [i18n.language]);
  const dispatch = useAppDispatch();

  const changeLanguage = (value: LanguageTag) => {
    dispatch(changeLanguageAsyncThunk(value));
  };

  return {
    currentLanguage,
    changeLanguage,
  };
};
