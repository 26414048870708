import { useDeferredValue } from 'react';

import { useAppSelector, useGetDashboardDataQuery } from '../../../shared';
import { dashboardAdapter } from './dashboardAdapter';

export const useDashboard = () => {
  const filters = useAppSelector((state) => state.dashboard.filters);
  const deferredFilters = useDeferredValue(filters);

  const { data, isFetching, isUninitialized } = useGetDashboardDataQuery(deferredFilters, {
    selectFromResult: (data) => {
      return {
        ...data,
        data: dashboardAdapter.addMany(dashboardAdapter.getInitialState(), data.data || []),
      };
    },
  });

  return {
    dataDashboard: data,
    isFetchingDashboard: isFetching,
    isUninitializedDashboard: isUninitialized,
  };
};
