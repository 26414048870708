import React, { FC } from 'react';

import { LogoSvgImage } from '../assets/images/LogoSvgImage';
import { useTheme } from '../themes';

interface ILogoProps {
  isMiny?: boolean;
}

export const Logo: FC<ILogoProps> = ({ isMiny }) => {
  const { isDark, theme } = useTheme();

  return (
    <LogoSvgImage
      textColor={isDark ? theme.palette.text.primary : '#1C1E29'}
      isMiny={isMiny}
      sx={{
        width: '9em',
        height: '1.4em',
      }}
    />
  );
};
