import { useAppSelector, useUpdateCampaignMutation } from '../../../../shared';
import { UpdateCampaignRequest } from '../../../../shared/api/services/campaign/dto/UpdateCampaignRequest';

export const useUpdateCampaign = () => {
  const [triggerUpdateCampaign, { isLoading: isLoadingUpdateCampaign }] = useUpdateCampaignMutation();
  const activeChannelId = useAppSelector((state) => state.activeChannel.id);

  const updateCampaign = async (request: Omit<UpdateCampaignRequest, 'channelId'>) => {
    if (!activeChannelId) {
      return;
    }
    triggerUpdateCampaign({ ...request, channelId: activeChannelId }).unwrap();
  };

  return {
    updateCampaign,
    isLoadingUpdateCampaign,
  };
};
